import PropTypes from "prop-types"
import React from "react"

import { connect } from "react-redux"
import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "../Common/LanguageDropdown"
import ProfileMenu from "../Common/ProfileMenu"

import logo from "../../assets/images/logo.png"
import logoLightSvg from "../../assets/images/logo-light.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

const Header = () => {
  // const [search, setsearch] = useState(false)

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {
              // <form className="app-search d-none d-lg-block">
              //   <div className="position-relative">
              //     <input
              //       type="text"
              //       className="form-control"
              //       placeholder={props.t("Search") + "..."}
              //     />
              //     <span className="bx bx-search-alt" />
              //   </div>
              // </form>
            }
          </div>
          <div className="d-flex">
            {
              // <div className="dropdown d-inline-block d-lg-none ms-2">
              //   <button
              //     onClick={() => {
              //       setsearch(!search)
              //     }}
              //     type="button"
              //     className="btn header-item noti-icon "
              //     id="page-header-search-dropdown"
              //   >
              //     <i className="mdi mdi-magnify" />
              //   </button>
              //   <div
              //     className={
              //       search
              //         ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
              //         : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              //     }
              //     aria-labelledby="page-header-search-dropdown"
              //   >
              //     <form className="p-3">
              //       <div className="form-group m-0">
              //         <div className="input-group">
              //           <input
              //             type="text"
              //             className="form-control"
              //             placeholder="Search ..."
              //             aria-label="Recipient's username"
              //           />
              //           <div className="input-group-append">
              //             <button className="btn btn-primary" type="submit">
              //               <i className="mdi mdi-magnify" />
              //             </button>
              //           </div>
              //         </div>
              //       </div>
              //     </form>
              //   </div>
              // </div>
            }

            <ProfileMenu />
            <LanguageDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
