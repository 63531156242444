import {
  GET_PLACE,
  GET_PLACE_FAIL,
  GET_PLACE_SUCCESS,
  LIST_PLACES,
  LIST_PLACES_FAIL,
  LIST_PLACES_SUCCESS,
  ADD_ORDER,
  ADD_ORDER_FAIL,
  ADD_ORDER_SUCCESS,
  CANCEL_ORDER,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_SUCCESS,
  EDIT_PLACE,
  EDIT_PLACE_FAIL,
  EDIT_PLACE_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getPlace = (place, history) => {
  return {
    type: GET_PLACE,
    payload: { place, history },
  };
};

export const getPlaceSuccess = place => {
  toast.success(i18n.t(place.message));
  return {
    type: GET_PLACE_SUCCESS,
    payload: place,
  };
};

export const getPlaceFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_PLACE_FAIL,
    payload: fail,
  };
};

export const listPlaces = history => {
  return {
    type: LIST_PLACES,
    payload: { history },
  };
};

export const listPlacesSuccess = places => {
  // toast.success(i18n.t(places.message));
  return {
    type: LIST_PLACES_SUCCESS,
    payload: places.details,
  };
};

export const listPlacesFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LIST_PLACES_FAIL,
    payload: fail,
  };
};


export const addOrder = (place, history) => {
  return {
    type: ADD_ORDER,
    payload: { place, history },
  };
};

export const addOrderSuccess = place => {
  toast.success(i18n.t(place.message));
  return {
    type: ADD_ORDER_SUCCESS,
    payload: place.details,
  };
};

export const addOrderFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: ADD_ORDER_FAIL,
    payload: fail,
  };
};

export const cancelOrder = (place, history) => {
  return {
    type: CANCEL_ORDER,
    payload: { place, history },
  };
};

export const cancelOrderSuccess = place => {
  toast.success(i18n.t(place.message));
  return {
    type: CANCEL_ORDER_SUCCESS,
    payload: place.details,
  };
};

export const cancelOrderFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: CANCEL_ORDER_FAIL,
    payload: fail,
  };
};


export const editPlace = (place, history) => {
  return {
    type: EDIT_PLACE,
    payload: { place, history },
  };
};

export const editPlaceSuccess = place => {
  toast.success(i18n.t(place.message));
  return {
    type: EDIT_PLACE_SUCCESS,
    payload: place.details,
  };
};

export const editPlaceFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: EDIT_PLACE_FAIL,
    payload: fail,
  };
};
