import {
  GET_HARDWARE,
  GET_HARDWARE_FAIL,
  GET_HARDWARE_SUCCESS,
  LIST_HARDWARE,
  LIST_HARDWARE_FAIL,
  LIST_HARDWARE_SUCCESS,
  EDIT_HARDWARE,
  EDIT_HARDWARE_FAIL,
  EDIT_HARDWARE_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  hardware: null,
  hardwares: null,
  loading: false,
  editUpdated: false,
};

const Hardware = (state = initialState, action) => {
  switch (action.type) {
    case GET_HARDWARE:
      state = {
        ...state,
        hardware: null,
        loading: true,
        error: null,
      };
      break;
    case GET_HARDWARE_SUCCESS:
      state = {
        ...state,
        loading: false,
        hardware: action.payload,
        error: null,
      };
      break;
    case GET_HARDWARE_FAIL:
      state = {
        ...state,
        loading: false,
        hardware: null,
        error: action.payload,
      };
      break;
    case LIST_HARDWARE:
      state = {
        ...state,
        hardwares: null,
        loading: true,
        error: null,
      };
      break;
    case LIST_HARDWARE_SUCCESS:
      state = {
        ...state,
        loading: false,
        hardwares: action.payload,
        error: null,
      };
      break;
    case LIST_HARDWARE_FAIL:
      state = {
        ...state,
        loading: false,
        hardwares: null,
        error: action.payload,
      };
      break;
      case EDIT_HARDWARE:
        state = {
          ...state,
          editUpdated: false,
          loading: true,
          error: null,
        };
        break;
      case EDIT_HARDWARE_SUCCESS:
        state = {
          ...state,
          editUpdated: true,
          loading: false,
          error: null,
        };
        break;
      case EDIT_HARDWARE_FAIL:
        state = {
          ...state,
          editUpdated: false,
          loading: false,
          error: action.payload,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Hardware;
