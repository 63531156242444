import React, {useState} from "react"

//redux
import withRouter from "components/Common/withRouter"

// i18n
import { withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

import Done from "./Done"
import Again from "./Again"

const Thanks = () => {
  const [doneOrAgain, setDoneOrAgain] = useState("done")

  return (
    <React.Fragment>
      <Fade>
        {doneOrAgain === "done" ? (
          <Done
            doneOrAgain={doneOrAgain}
            goToAgain={() => setDoneOrAgain("again")}
          />
        ) : (
          <Again />
        )}
      </Fade>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Thanks))
