import {
  GET_BADGE,
  GET_BADGE_INFO,
  GET_BADGE_SUCCESS,
  GET_BADGE_FAIL,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAIL,
  RESET,
  RESET_SUCCESS,
  RESET_FAIL,
  PRINT_SCORES,
  PRINT_SCORES_SUCCESS,
  PRINT_SCORES_FAIL,
  ACCESS_ADMIN,
  ACCESS_ADMIN_SUCCESS,
  ACCESS_ADMIN_FAIL,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PROGRESS,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  SEND_SURVEY,
  SEND_SURVEY_SUCCESS,
  SEND_SURVEY_FAIL,
  TAKE_CONTROL,
  TAKE_CONTROL_SUCCESS,
  TAKE_CONTROL_FAIL,
} from "./actionTypes"

import { toast } from "react-toastify"
import i18n from "../../i18n/i18n"

export const getBadge = (badge, history) => {
  return {
    type: GET_BADGE,
    payload: { badge, history },
  }
}

export const getBadgeInfo = (badge, history) => {
  return {
    type: GET_BADGE_INFO,
    payload: { badge, history },
  }
}

export const getBadgeSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: GET_BADGE_SUCCESS,
    payload: badge,
  }
}

export const getBadgeFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: GET_BADGE_FAIL,
    payload: error,
  }
}

export const printScores = (badge, history) => {
  return {
    type: PRINT_SCORES,
    payload: { badge, history },
  }
}

export const printScoresSuccess = badge => {
  toast.success(i18n.t(badge.message))
  return {
    type: PRINT_SCORES_SUCCESS,
    payload: badge,
  }
}

export const printScoresFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: PRINT_SCORES_FAIL,
    payload: error,
  }
}

export const reset = (badge, history) => {
  return {
    type: RESET,
    payload: { badge, history },
  }
}

export const resetSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: RESET_SUCCESS,
    payload: badge,
  }
}

export const resetFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: RESET_FAIL,
    payload: error,
  }
}

export const setStatus = (badge, history) => {
  return {
    type: SET_STATUS,
    payload: { badge, history },
  }
}

export const setStatusSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: SET_STATUS_SUCCESS,
    payload: badge,
  }
}

export const setStatusFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: SET_STATUS_FAIL,
    payload: error,
  }
}

export const accessAdmin = (badge, history) => {
  return {
    type: ACCESS_ADMIN,
    payload: { badge, history },
  }
}

export const accessAdminSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: ACCESS_ADMIN_SUCCESS,
    payload: badge,
  }
}

export const accessAdminFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      // toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: ACCESS_ADMIN_FAIL,
    payload: error,
  }
}

export const uploadPhoto = (badge, history) => {
  return {
    type: UPLOAD_PHOTO,
    payload: { badge, history },
  }
}

export const uploadPhotoProgress = progress => {
  return {
    type: UPLOAD_PHOTO_PROGRESS,
    payload: progress,
  }
}

export const uploadPhotoSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: UPLOAD_PHOTO_SUCCESS,
    payload: badge,
  }
}

export const uploadPhotoFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: UPLOAD_PHOTO_FAIL,
    payload: error,
  }
}

export const sendEmail = (badge, history) => {
  return {
    type: SEND_EMAIL,
    payload: { badge, history },
  }
}

export const sendEmailSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: badge,
  }
}

export const sendEmailFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: SEND_EMAIL_FAIL,
    payload: error,
  }
}

export const sendSurvey = (badge, history) => {
  return {
    type: SEND_SURVEY,
    payload: { badge, history },
  }
}

export const sendSurveySuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: SEND_SURVEY_SUCCESS,
    payload: badge,
  }
}

export const sendSurveyFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: SEND_SURVEY_FAIL,
    payload: error,
  }
}

export const takeControl = (badge, history) => {
  return {
    type: TAKE_CONTROL,
    payload: { badge, history },
  }
}

export const takeControlSuccess = badge => {
  // toast.success(i18n.t(badge.message))
  return {
    type: TAKE_CONTROL_SUCCESS,
    payload: badge,
  }
}

export const takeControlFail = error => {
  if (error.status !== undefined) {
    if (error.status === "info") {
      toast.info(i18n.t(error.message))
    } else if (error.status === "warning") {
      toast.warning(i18n.t(error.message))
    } else {
      toast.error(i18n.t(error.message))
    }
  } else {
    toast.error(i18n.t(error))
  }
  return {
    type: TAKE_CONTROL_FAIL,
    payload: error,
  }
}
