import PropTypes from "prop-types"
import React from "react"

import { Container, Col } from "reactstrap"

//redux
//redux
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

// bucket
import { ROOT_PROD_BUCKET } from "../../../helpers/url"

const Thanks = props => {
  return (
    <React.Fragment>
      <Fade>
        <Container>
          <div className="text-center pt-5 mt-5 mb-5">
            <h3 className="text-primary fw-semibold mb-3">
              {props.email !== "" ? (
                <Trans i18nKey="badge.thanks.title"></Trans>
              ) : (
                <Trans i18nKey="badge.thanks.noEmail"></Trans>
              )}
            </h3>
            <Col className="offset-1 col-10">
              <img
                src={
                  ROOT_PROD_BUCKET +
                  "/config/" +
                  props.router.params.idPlace +
                  "/badges/" +
                  props.router.params.idBadge +
                  ".jpg?timestamp=" +
                  Date.now()
                }
                alt=""
                className="img-fluid img-thumbnail img-preview"
              />
            </Col>
            <Col className="mt-5 mb-3 pb-5">
              <h5>
                {props.email !== "" ? (
                  <Trans i18nKey="badge.thanks.description"></Trans>
                ) : (
                  ""
                )}
                <br />
                <u>{props.email !== "" ? props.email : ""}</u>
                <br />
                {props.prepaidPrint ? (
                  <Trans i18nKey="badge.thanks.getPrepaidPrint"></Trans>
                ) : (
                  ""
                )}
              </h5>
            </Col>
          </div>
          <div className="mb-3 footer bg-transparent">
            <div className="d-grid">
              <button
                className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                type="button"
                disabled={props.loading}
                onClick={() => {
                  props.goToAgain()
                }}
              >
                <Trans i18nKey="badge.thanks.submit"></Trans>
              </button>
            </div>
          </div>
        </Container>
      </Fade>
    </React.Fragment>
  )
}

Thanks.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  email: PropTypes.string,
  prepaidPrint: PropTypes.bool,
  goToAgain: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, status, email, prepaidPrint } = state.Badge
  return { loading, status, email, prepaidPrint }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Thanks))
)
