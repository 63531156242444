import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { map } from "lodash"

import { connect } from "react-redux"

import { Link } from "react-router-dom"
import withRouter from "./withRouter"

import { ROOT_BUCKET } from "../../helpers/url"
import logoDark from "../../assets/images/brand.png"

//i18n
import { withTranslation } from "react-i18next"

const PlaceDropdown = (props) => {
  const [menu, setMenu] = useState(false)
  const [authUser, setAuthUser] = useState(null)

  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("authUser")))
  })

  const toggle = () => {
    setMenu(!menu)
  }

  if (!authUser) return ""
  if (!authUser.places) return ""

  let logo = (
    <span className="display-6" style={{ fontFamily: "impact" }}>
      -
    </span>
  )
  {
    map(Object.keys(authUser.places), key => {
      if (props.router.params.idPlace == authUser.places[key].id)
        logo = (
          <img
            src={
              props.router.params.idPlace
                ? ROOT_BUCKET +
                  "/config/" +
                  props.router.params.idPlace +
                  "/img/logo.png"
                : logoDark
            }
            alt=""
            height="42"
            className="me-1"
          />
        )
    })
  }

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item" tag="button">
          {logo}
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(authUser.places), key => (
            <DropdownItem
              key={key}
              className={`notify-item ${
                props.router.params.idPlace === authUser.places[key].id
                  ? "active"
                  : "none"
              }`}
            >
              <Link to={"/place/" + authUser.places[key].id}>
                <img
                  src={
                    ROOT_BUCKET +
                    "/config/" +
                    authUser.places[key].id +
                    "/img/logo.png"
                  }
                  alt={authUser.places[key].name}
                  className="me-1"
                  height="42"
                />
              </Link>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

PlaceDropdown.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  user: PropTypes.object,
  router: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { error, success, user, loading } = state.Profile
  return { error, success, user, loading }
}

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PlaceDropdown)))
