import React from "react"
import { Container, Row, Col } from "reactstrap"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

//Import Images
import error from "../../../assets/images/notFound.png"

const notFound = () => {
  //meta title
  document.title = i18n.t("badge.title") + " | GreenPlay Innovation"

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  4<i className="fas fa-golf-ball text-primary display-3" />4
                </h1>
                <h4 className="text-uppercase">
                  <Trans i18nKey="badge.notFound.title"></Trans>
                </h4>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(notFound)
