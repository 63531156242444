import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { GET_USER, LIST_USERS, LOGIN_AS } from "./actionTypes";
import {
  getUserSuccess,
  getUserFail,
  listUsersSuccess,
  listUsersFail,
  loginAsSuccess,
  loginAsFail,
} from "./actions";

//Include Both Helper File with needed methods
import { getUserCall, listUsersCall, loginAsCall } from "../../helpers/backend";

function* getUser({ payload: { user, history } }) {
  try {
    const response = yield call(getUserCall, user);
    if (response.status === "success") {
      yield put(getUserSuccess(response));
    } else {
      yield put(getUserFail(response));
    }
  } catch (error) {
    yield put(getUserFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* listUsers({ payload: { history } }) {
  try {
    const response = yield call(listUsersCall);
    if (response.status === "success") {
      yield put(listUsersSuccess(response));
    } else {
      yield put(listUsersFail(response));
    }
  } catch (error) {
    yield put(listUsersFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* loginAs({ payload: { user, history } }) {
  try {
    const response = yield call(loginAsCall, user);
    if (response.status === "success") {
      yield put(loginAsSuccess(response));
      localStorage.setItem("authUser", JSON.stringify(response.details));
      if (response.details.places.length > 0)
        history("/place/" + response.details.places[0].id);
      else history("/profile");
    } else {
      yield put(loginAsFail(response));
    }
  } catch (error) {
    yield put(loginAsFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

export function* watchUser() {
  yield takeEvery(GET_USER, getUser);
  yield takeEvery(LIST_USERS, listUsers);
  yield takeEvery(LOGIN_AS, loginAs);
}

function* UserSaga() {
  yield all([fork(watchUser)]);
}

export default UserSaga;
