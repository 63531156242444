import { all, fork } from "redux-saga/effects";

//public
import BadgeSaga from "./badge/saga";
import LayoutSaga from "./layout/saga";
import LoginSaga from "./authentication/login/saga"
import ProfileSaga from "./authentication/profile/saga"
import PlaceSaga from "./place/saga"
import HardwareSaga from "./hardware/saga"
import UserSaga from "./user/saga"
import OrderSaga from "./order/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(BadgeSaga),
    fork(LoginSaga),
    fork(ProfileSaga),
    fork(PlaceSaga),
    fork(HardwareSaga),
    fork(UserSaga),
    fork(OrderSaga)
  ]);
}
