import PropTypes from "prop-types"
import React, { useEffect } from "react"

//redux
import { connect, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// actions
import { getBadge } from "../../../store/actions"

// i18n
import { withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// screens
import Welcome from "./Welcome"
import Activate from "./Activate"
import PlayersCount from "./PlayersCount"
import PlayersNames from "./PlayersNames"
import Thanks from "./Thanks"
// import Congrats from "./Congrats"
import Preview from "./Preview"
import SendEmail from "./SendEmail"
import Survey from "./Survey"
import NotFound from "./NotFound"
import Game from "./Game"
import ReadOnly from "./ReadOnly"

const Badge = props => {
  //meta title
  document.title = i18n.t("badge.title") + " | GreenPlay Innovation"

  const dispatch = useDispatch()
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    dispatch(
      getBadge(
        {
          idPlace: props.router.params.idPlace || 0,
          idBadge: props.router.params.idBadge || 0,
          token: props.router.params.token || "",
        },
        props.router.navigate
      )
    )
  }, [])

  if (props.status === "unknown") {
    return ""
  }

  if (props.status === "notFound") {
    return <NotFound />
  }

  if (props.status === "disabled" && props.type === "none") {
    return <Activate />
  }

  if (props.readOnly === true) {
    return <ReadOnly />
  }

  if ((props.status === "enabled" && props.type === "none") || (props.status === "disabled" && props.type !== "none")) {
    return <Welcome />
  }

  if (props.playersCount <= 0) {
    return <PlayersCount />
  }

  if (props.players.length < props.playersCount) {
    return <PlayersNames />
  }

  if (props.status === "preview") {
    return <Preview />
  }

  if (props.status === "sendEmail") {
    return <SendEmail />
  }

  if (props.status === "survey") {
    return <Survey />
  }

  if (props.status === "thanks") {
    return <Thanks />
  }

  return <Game />
}

Badge.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  type: PropTypes.string,
  playersCount: PropTypes.number,
  players: PropTypes.array,
  green: PropTypes.array,
  overlay: PropTypes.object,
  readOnly: PropTypes.bool,
}

const mapStateToProps = state => {
  const {
    loading,
    status,
    type,
    playersCount,
    players,
    green,
    overlay,
    readOnly,
  } = state.Badge
  return {
    loading,
    status,
    type,
    playersCount,
    players,
    green,
    overlay,
    readOnly,
  }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Badge))
)
