import PropTypes from "prop-types"
import React from "react"

import {
  Col,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Alert,
  Label,
  Input,
} from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// actions
import { sendEmail } from "../../../store/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// effect
import { Fade } from "react-awesome-reveal"

const Email = props => {
  const dispatch = useDispatch()

  const sendEmailValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
      status: "survey",
      email: "",
      newsletter: false,
      i18n: localStorage.getItem("i18nextLng") || "fr",
      game: JSON.stringify(props.game) || "",
      scores: JSON.stringify(props.scores) || "",
      pt: i18n.t("badge.scores.pt"),
      pts: i18n.t("badge.scores.pts"),
    },
    validationSchema: Yup.object({
      newsletter: Yup.boolean(),
      email: Yup.string()
        .required(i18n.t("badge.sendEmail.enterEmail"))
        .email(i18n.t("badge.sendEmail.enterValidEmail")),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(sendEmail(values))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <Col className="mt-5 pb-5 text-center">
              <h3 className="text-primary fw-semibold display-5 mt-5">
                <Trans i18nKey="badge.sendEmail.title"></Trans>
              </h3>
              <h5>
                <Trans i18nKey="badge.sendEmail.description"></Trans>
              </h5>
            </Col>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  sendEmailValidation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                <div className="mb-3">
                  <Label for="email" className="form-label">
                    <Trans i18nKey="badge.sendEmail.email" />*
                  </Label>
                  <Input
                    name="email"
                    id="email"
                    type="email"
                    className="p-3 border-input"
                    disabled={props.loading}
                    autoFocus={true}
                    autoComplete="on"
                    onChange={sendEmailValidation.handleChange}
                    onBlur={sendEmailValidation.handleBlur}
                    invalid={
                      sendEmailValidation.touched.email &&
                      sendEmailValidation.errors.email
                        ? true
                        : false
                    }
                  />
                  {sendEmailValidation.touched.email &&
                  sendEmailValidation.errors.email ? (
                    <FormFeedback type="invalid">
                      {sendEmailValidation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3 d-none">
                  <FormGroup className="mb-3">
                    <div className="form-check">
                      <Input
                        type="checkbox"
                        disabled={props.loading}
                        className="form-check-input"
                        name="newsletter"
                        id="newsletter"
                        onChange={sendEmailValidation.handleChange}
                        onBlur={sendEmailValidation.handleBlur}
                        invalid={
                          sendEmailValidation.touched.newsletter &&
                          sendEmailValidation.errors.newsletter
                            ? true
                            : false
                        }
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="newsletter"
                        for="newsletter"
                      >
                        {" "}
                        <Trans i18nKey="badge.sendEmail.newsletter"></Trans>
                      </Label>
                    </div>
                  </FormGroup>
                  {sendEmailValidation.touched.newsletter &&
                  sendEmailValidation.errors.newsletter ? (
                    <FormFeedback type="invalid">
                      {sendEmailValidation.errors.newsletter}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mt-3 mb-4 d-grid">
                  <button
                    className={
                      "btn btn-block btn-rounded btn-lg " +
                      (sendEmailValidation.isValid && sendEmailValidation.dirty
                        ? "btn-primary"
                        : "btn-disabled-light")
                    }
                    type="submit"
                    disabled={
                      props.loading ||
                      !(
                        sendEmailValidation.isValid && sendEmailValidation.dirty
                      )
                    }
                  >
                    {props.loading ? (
                      <i className="bx bx-loader bx-spin"></i>
                    ) : (
                      <span>
                        <i className="fa fa-solid fa-envelope"></i>{" "}
                        <Trans i18nKey="badge.sendEmail.submit"></Trans>
                      </span>
                    )}
                  </button>
                </div>
              </Form>
              <div className="text-center">
                <button
                  className={"btn btn-rounded btn-sm btn-default btn-skip"}
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    dispatch(
                      sendEmail({
                        idPlace: props.router.params.idPlace,
                        idBadge: props.router.params.idBadge,
                        token: props.router.params.token,
                        status: "survey",
                        email: "",
                        newsletter: false,
                        i18n: localStorage.getItem("i18nextLng"),
                        game: JSON.stringify(props.game),
                        scores: JSON.stringify(props.scores),
                        pt: i18n.t("badge.scores.pt"),
                        pts: i18n.t("badge.scores.pts"),
                      })
                    )
                  }}
                >
                  {props.loading ? (
                    <i className="bx bx-loader bx-spin"></i>
                  ) : (
                    <Trans i18nKey="badge.sendEmail.skip"></Trans>
                  )}
                </button>
              </div>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

Email.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  game: PropTypes.object,
  scores: PropTypes.array,
}

const mapStateToProps = state => {
  const { loading, status, game, scores } = state.Badge
  return { loading, status, game, scores }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Email))
)
