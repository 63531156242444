import PropTypes from "prop-types"
import React from "react"

import { Row, Col, Card, Container } from "reactstrap"

//redux
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

// import images
import brand from "assets/images/brand.png"

// effect
import { Fade } from "react-awesome-reveal"

import Footer from "../../../components/HorizontalBadgeLayout/Footer"

const Home = () => {
  //meta title
  document.title = "GreenPlay Innovation"

  return (
    <React.Fragment>
      <Fade>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center vertical-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <Row>
                    <Col className="text-center mb-5">
                      <img src={brand} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Footer/>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

Home.propTypes = {
  router: PropTypes.object,
}

export default withRouter(connect()((Home)))
