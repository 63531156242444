import PropTypes from "prop-types"
import React from "react"

//redux
import { connect, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// i18n
import { withTranslation } from "react-i18next"

// actions
import { setStatus } from "../../store/actions"

const BackButton = props => {
  const dispatch = useDispatch()

  const statusWithPreviousFunctions = ["preview", "sendEmail"]

  return (
    <React.Fragment>
      <button
        type="button"
        className={
          "btn btn-sm px-3 font-size-16 header-item " +
          (statusWithPreviousFunctions.includes(props.status) ? "" : "d-none")
        }
        disabled={props.loading}
        onClick={() => {
          dispatch(
            setStatus(
              {
                idPlace: props.router.params.idPlace || "",
                idBadge: props.router.params.idBadge || 0,
                token: props.router.params.token || "",
                status:
                  statusWithPreviousFunctions.indexOf(props.status) - 1 > -1
                    ? statusWithPreviousFunctions[
                        statusWithPreviousFunctions.indexOf(props.status) - 1
                      ]
                    : "game",
              },
              props.router.navigate
            )
          )
        }}
        data-target="#topnav-menu-content"
      >
        <i className="fa fa-fw fa-chevron-left" />
      </button>
    </React.Fragment>
  )
}

BackButton.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  playersCount: PropTypes.number,
  players: PropTypes.array,
  green: PropTypes.array,
  overlay: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, status, playersCount, players, green, overlay } = state.Badge
  return { loading, status, playersCount, players, green, overlay }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(BackButton))
)
