import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  LIST_USERS,
  LIST_USERS_FAIL,
  LIST_USERS_SUCCESS,
  LOGIN_AS,
  LOGIN_AS_FAIL,
  LOGIN_AS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  user: null,
  users: null,
  loading: false,
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        error: null,
      };
      break;
    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
      break;
    case GET_USER_FAIL:
      state = {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };
      break;
    case LIST_USERS:
      state = {
        ...state,
        users: null,
        loading: true,
        error: null,
      };
      break;
    case LIST_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload,
        error: null,
      };
      break;
    case LIST_USERS_FAIL:
      state = {
        ...state,
        loading: false,
        users: null,
        error: action.payload,
      };
      break;
    case LOGIN_AS:
      state = {
        ...state,
        user: null,
        loading: true,
        error: null,
      };
      break;
    case LOGIN_AS_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
      break;
    case LOGIN_AS_FAIL:
      state = {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default User;
