import PropTypes from "prop-types"
import React, { useState } from "react"

import { Button, Container, Col, Row, Modal, Input } from "reactstrap"

//redux
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

const Hole = props => {

  const [game, setGame] = useState(props.game)
  
  const onChangeInput = (e, jeu, player) => {
    let { value, min, max } = e.target
    value = Math.max(Number(min), Math.min(Number(max), Number(value)))
    const editData = game
    editData["players"]["player_" + player][jeu] = parseInt(value)
    // localStorage.setItem("game", JSON.stringify(editData))
    setGame(editData)
    props.updateGame()
  }

  const onMinusInput = (jeu, player) => {
    const editData = game
    let value =
      (editData["players"]["player_" + player][jeu] === ""
        ? 0
        : parseInt(editData["players"]["player_" + player][jeu])) - 1
    value = Math.max(0, Number(value))
    editData["players"]["player_" + player][jeu] = parseInt(value)
    // localStorage.setItem("game", JSON.stringify(editData))
    setGame(editData)
    props.updateGame()

  }

  const onPlusInput = (jeu, player) => {
    const editData = game
    let value =
      (editData["players"]["player_" + player][jeu] === ""
        ? 0
        : parseInt(editData["players"]["player_" + player][jeu])) + 1
    value = Math.min(8, Number(value))
    editData["players"]["player_" + player][jeu] = parseInt(value)
    // localStorage.setItem("game", JSON.stringify(editData))
    setGame(editData)
    props.updateGame()
  }

  const updateHoleStatus = status => {
    const editData = game
    if (status === 1) {
      for (let player = 0; player < props.players.length; player++) {
        editData["players"]["player_" + player][props.activeHole] =
          editData["players"]["player_" + player][props.activeHole] === ""
            ? 0
            : editData["players"]["player_" + player][props.activeHole]
      }
    }
    editData["holes"][props.activeHole] =
      editData["holes"][props.activeHole] !== 1 ? status : 1
    // localStorage.setItem("game", JSON.stringify(editData))
    setGame(editData)
  }

  return (
    <React.Fragment>
      <div>
        {props.activeHole > -1 ? (
          <Modal
            isOpen={props.show}
            size="xl"
            centered
            backdrop={"static"}
            fullscreen={true}
          >
            <div className="modal-header" style={{ display: "block" }}>
              <h5 className="modal-title mt-0">
                {props.activeHole > -1 ? (
                  <div className="text-center">
                    <h4 className="mb-0 mt-1">
                      {props.green[props.activeHole].jeu}.
                      {props.green[props.activeHole].name}
                      <br />
                      <span className="fs-6 text text-secondary">
                        Par {props.green[props.activeHole].par}
                      </span>
                    </h4>
                  </div>
                ) : (
                  ""
                )}
              </h5>
              <button
                type="button"
                onClick={() => {
                  updateHoleStatus(0)
                  props.updateGame()
                  props.close()
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="page-content">
                <Container fluid>
                  {props.players.map((playerValue, playerIndex) => (
                    <Row className="mb-3" key={playerIndex}>
                      <Col xs={5} className="col-form-label label-player-point">
                        {" "}
                        <label htmlFor={props.activeHole + "_" + playerIndex}>
                          {playerValue}
                        </label>
                      </Col>
                      <Col xs={2}>
                        <Button
                          color="link"
                          className="btn btn-link btn-plus-minus-point waves-effect float-end"
                          onClick={() =>
                            onMinusInput(props.activeHole, playerIndex)
                          }
                        >
                          -
                        </Button>
                      </Col>
                      <Col xs={3}>
                        <Input
                          className="form-control input-point"
                          type="number"
                          pattern="\d*"
                          id={props.activeHole + "_" + playerIndex}
                          name={props.activeHole + "_" + playerIndex}
                          min={0}
                          max={8}
                          value={
                            game["players"]["player_" + playerIndex][
                              props.activeHole
                            ] || 0
                          }
                          onChange={e =>
                            onChangeInput(e, props.activeHole, playerIndex)
                          }
                          onFocus={e => e.target.select()}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          color="link"
                          className="btn btn-link btn-plus-minus-point waves-effect btn-plus-point"
                          onClick={() =>
                            onPlusInput(props.activeHole, playerIndex)
                          }
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </Container>
              </div>
            </div>
            <div className="mb-3 footer bg-transparent">
              <div className="d-grid">
                <button
                  className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    updateHoleStatus(1)
                    props.updateGame()
                    props.close()
                  }}
                >
                  <Trans i18nKey="badge.hole.submit"></Trans>
                </button>
              </div>
            </div>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  )
}

Hole.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  players: PropTypes.array,
  green: PropTypes.array,
  activeHole: PropTypes.number,
  show: PropTypes.bool,
  close: PropTypes.func,
  game: PropTypes.object,
  updateGame: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, status, players, green } = state.Badge
  return {
    loading,
    status,
    players,
    green,
  }
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(Hole)))
