import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE, EDIT_LANGUAGE } from "./actionTypes";
import {
  getProfileSuccess,
  getProfileFail,
  editProfileSuccess,
  editProfileFail,
  editLanguageSuccess,
  editLanguageFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  profileCall,
  editProfileCall,
  editLanguageCall
} from "../../../helpers/backend";

function* editProfile({ payload: { user, history } }) {
  try {
    const response = yield call(editProfileCall, user);
    if (response.status === "success") {
      localStorage.setItem("authUser", JSON.stringify(response.details));
      yield put(editProfileSuccess(response));
    } else yield put(editProfileFail(response));
  } catch (error) {
    yield put(editProfileFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* editLanguage({ payload: { user, history } }) {
  try {
    const response = yield call(editLanguageCall, user);
    if (response.status === "success") {
      yield put(editLanguageSuccess(response));
    } else yield put(editLanguageFail(response));
  } catch (error) {
    yield put(editLanguageFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* getProfile({ payload: { history } }) {
  try {
    const response = yield call(profileCall);
    if (response.status === "success") {
      yield put(getProfileSuccess(response));
    } else yield put(getProfileFail(response));
  } catch (error) {
    yield put(getProfileFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfile);
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(EDIT_LANGUAGE, editLanguage);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
