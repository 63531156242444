import React from "react" // , { useState }
import PropTypes from "prop-types"

import { connect } from "react-redux"

// import withRouter from "components/Common/withRouter"

// Redux Store
import { toggleLeftmenu } from "../../store/actions"
// reactstrap

// Import menuDropdown
// Import menuDropdown
import LanguageDropdown from "../Common/LanguageDropdown"
import PlaceDropdown from "../Common/PlaceDropdown";
import ProfileMenu from "../Common/ProfileMenu";

//i18n
import { withTranslation } from "react-i18next"

const Header = (props) => {
  // const [menu, setMenu] = useState(false)
  // const [isSearch, setSearch] = useState(false)

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <PlaceDropdown />
            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <ProfileMenu />
            <LanguageDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  toggleLeftmenu,
})(withTranslation()(Header))
