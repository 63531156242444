import {
  GET_BADGE,
  GET_BADGE_INFO,
  GET_BADGE_SUCCESS,
  GET_BADGE_FAIL,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAIL,
  RESET,
  RESET_SUCCESS,
  RESET_FAIL,
  PRINT_SCORES,
  PRINT_SCORES_SUCCESS,
  PRINT_SCORES_FAIL,
  ACCESS_ADMIN,
  ACCESS_ADMIN_SUCCESS,
  ACCESS_ADMIN_FAIL,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PROGRESS,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAIL,
  SEND_EMAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  SEND_SURVEY,
  SEND_SURVEY_SUCCESS,
  SEND_SURVEY_FAIL,
  TAKE_CONTROL,
  TAKE_CONTROL_SUCCESS,
  TAKE_CONTROL_FAIL,
} from "./actionTypes"

const initialState = {
  error: "",
  token: "",
  type: "unknown",
  loading: false,
  status: "unknown",
  playersCount: 0,
  prepaidPrint: false,
  progress: 0,
  players: [],
  green: [],
  overlay: {},
  rules: {},
  survey: {},
  email: "",
  adminAccess: false,
  readOnly: true,
  game: {},
  scores: [],
  previousGames: [],
}

const badge = (state = initialState, action) => {
  switch (action.type) {
    case GET_BADGE:
      state = {
        ...state,
        loading: true,
        status: "unknown",
      }
      break
    case GET_BADGE_INFO:
      state = {
        ...state,
        loading: false,
        token: action.payload.details,
      }
      break
    case GET_BADGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        status: action.payload.status,
        type: action.payload.type,
        playersCount: action.payload.playersCount,
        prepaidPrint: action.payload.prepaidPrint,
        players: action.payload.players,
        green: action.payload.green,
        overlay: action.payload.overlay,
        rules: action.payload.rules,
        survey: action.payload.survey,
        readOnly: action.payload.readOnly,
        scores: action.payload.scores,
        game: action.payload.game,
      }
      break
    case GET_BADGE_FAIL:
      state = {
        ...state,
        loading: false,
        status: "notFound",
      }
      break
    case SET_STATUS:
      state = {
        ...state,
        loading: true,
        progress: 0,
      }
      break
    case SET_STATUS_SUCCESS:
      state = {
        ...action.payload,
        loading: false,
      }
      break
    case SET_STATUS_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case RESET:
      state = {
        ...state,
        loading: true,
      }
      break
    case RESET_SUCCESS:
      state = {
        ...state,
        loading: false,
        status: action.payload.status,
        type: action.payload.type,
        playersCount: action.payload.playersCount,
        prepaidPrint: action.payload.prepaidPrint,
        players: action.payload.players,
        green: action.payload.green,
        overlay: action.payload.overlay,
        rules: action.payload.rules,
        survey: action.payload.survey,
        readOnly: action.payload.readOnly,
        scores: action.payload.scores,
        game: action.payload.game,
      }
      break
    case RESET_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case PRINT_SCORES:
      state = {
        ...state,
        loading: true,
      }
      break
    case PRINT_SCORES_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case PRINT_SCORES_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case ACCESS_ADMIN:
      state = {
        ...state,
        loading: true,
        adminAccess: false,
        progress: 0,
        previousGames: [],
      }
      break
    case ACCESS_ADMIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        adminAccess: true,
        previousGames: action.payload,
      }
      break
    case ACCESS_ADMIN_FAIL:
      state = {
        ...state,
        loading: false,
        previousGames: [],
      }
      break
    case UPLOAD_PHOTO:
      state = {
        ...state,
        loading: true,
        progress: 0,
      }
      break
    case UPLOAD_PHOTO_PROGRESS:
      state = {
        ...state,
        loading: true,
        progress: action.payload,
      }
      break
    case UPLOAD_PHOTO_SUCCESS:
      state = {
        ...state,
        loading: false,
        progress: 100,
        status: action.payload.details.status,
      }
      break
    case UPLOAD_PHOTO_FAIL:
      state = {
        ...state,
        loading: false,
        progress: 0,
      }
      break
    case SEND_EMAIL:
      state = {
        ...state,
        loading: true,
        email: "",
      }
      break
    case SEND_EMAIL_SUCCESS:
      state = {
        ...state,
        loading: false,
        status: action.payload.status,
        email: action.payload.email,
      }
      break
    case SEND_EMAIL_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case SEND_SURVEY:
      state = {
        ...state,
        loading: true,
        email: "",
      }
      break
    case SEND_SURVEY_SUCCESS:
      state = {
        ...state,
        loading: false,
        status: action.payload.status,
        email: action.payload.email,
      }
      break
    case SEND_SURVEY_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    case TAKE_CONTROL:
      state = {
        ...state,
        loading: true,
      }
      break
    case TAKE_CONTROL_SUCCESS:
      state = {
        ...state,
        loading: false,
        readOnly: false
      }
      break
    case TAKE_CONTROL_FAIL:
      state = {
        ...state,
        loading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default badge
