import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux"

import { Helmet, HelmetProvider } from "react-helmet-async"

import Breadcrumbs from "../../components/Common/Breadcrumb"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../assets/scss/custom/plugins/_datatables.scss"

//store
import { listOrders } from "../../store/actions"

//i18n
import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

import { DateTime } from "luxon"

const Orders = props => {
  useEffect(() => {
    props.listOrders(props.router.navigate)
  }, [])

  const handleDetails = (cell, row) => {
    return (
      <span>
        {i18n.t("place.title")} : {row.place}
        <br />
        {i18n.t("place.printStation")} : {cell}
        <br />
        {i18n.t("place.quantity")} : {row.count6x4} <br />
        <b>
          {i18n.t("place.price")} : {row.price}€
        </b>{" "}
        <br />
      </span>
    )
  }

  const handleTracking = (cell, row) => {
    return (
      <span>
        {i18n.t("place.createdAt")} :{" "}
        {cell !== "-"
          ? DateTime.fromSQL(cell).toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
            )
          : cell}
        <br />
        {i18n.t("place.downloadedAt")} :{" "}
        {row.downloadedAt !== "-"
          ? DateTime.fromSQL(row.downloadedAt).toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
            )
          : row.downloadedAt}{" "}
        <br />
        {i18n.t("place.startPrintingAt")} :{" "}
        {row.startPrintingAt !== "-"
          ? DateTime.fromSQL(row.startPrintingAt).toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
            )
          : row.startPrintingAt}{" "}
        <br />
        {i18n.t("place.printedAt")} :{" "}
        {row.printedAt !== "-"
          ? DateTime.fromSQL(row.printedAt).toLocaleString(
              DateTime.DATETIME_MED_WITH_SECONDS
            )
          : row.printedAt}{" "}
        <br />
      </span>
    )
  }

  const columns = [
    {
      dataField: "idOrder",
      text: i18n.t("place.id"),
      sort: true,
    },
    {
      dataField: "idComputer",
      text: i18n.t("place.details"),
      formatter: handleDetails,
      sort: true,
    },
    {
      dataField: "createdAt",
      text: i18n.t("place.tracking"),
      formatter: handleTracking,
      sort: true,
    }
    
  ]

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: (props.orders && props.orders.length) || 0, // replace later with size(customers),
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    classes: "bg-primary bg-soft",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <HelmetProvider>
          <Helmet>
            <title>{i18n.t("sidebar.orders") + " | GreenPlay Innovation"}</title>
          </Helmet>
        </HelmetProvider>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"GreenPlay Innovation"}
            breadcrumbItem={props.t("sidebar.orders")}
          />
          {props.orders ? (
            <Row className="justify-content-center">
              <Col lg="12">
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-2 mb-5">
                      <h5 className="mt-3 mb-3">
                        <Trans i18nKey="sidebar.orders" />
                      </h5>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="idOrder"
                        columns={columns}
                        data={props.orders}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="idOrder"
                            columns={columns}
                            data={props.orders}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          placeholder={i18n.t("orders.search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"idOrder"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        noDataIndication={
                                          <Trans i18nKey="orders.emptyListOrders"></Trans>
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      {
                                        // <SizePerPageDropdownStandalone
                                        //   {...paginationProps}
                                        // />
                                      }
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <div className="pagination pagination-rounded justify-content-end">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <br />
                      <br />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Orders.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  orders: PropTypes.array,
  listOrders: PropTypes.func
}

const mapStateToProps = state => {
  const { error, loading, orders } = state.Order
  return { error, loading, orders }
}

export default connect(mapStateToProps, {listOrders
})(withRouter(withTranslation()(Orders)))
