import {
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_LANGUAGE,
  EDIT_LANGUAGE_SUCCESS,
  EDIT_LANGUAGE_FAIL,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.details.user,
      };
      break;
    case EDIT_PROFILE_FAIL:
      state = { ...state, error: action.payload };
      break;
    case EDIT_LANGUAGE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_LANGUAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.details.user,
      };
      break;
    case EDIT_LANGUAGE_FAIL:
      state = { ...state, error: action.payload };
      break;
    case GET_PROFILE:
      state = {
        ...state,
        loading: true,
        user: {},
      };
      break;
    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.details.user,
      };
      break;
    case GET_PROFILE_FAIL:
      state = { ...state, error: action.payload };
      break;
  }
  return state;
};

export default profile;
