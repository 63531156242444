import {
  GET_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  LIST_ORDERS,
  LIST_ORDERS_FAIL,
  LIST_ORDERS_SUCCESS,
  LAST_ORDERS,
  LAST_ORDERS_FAIL,
  LAST_ORDERS_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  order: null,
  orders: null,
  loading: false,
};

const Order = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
      state = {
        ...state,
        order: null,
        loading: true,
        error: null,
      };
      break;
    case GET_ORDER_SUCCESS:
      state = {
        ...state,
        loading: false,
        order: action.payload,
        error: null,
      };
      break;
    case GET_ORDER_FAIL:
      state = {
        ...state,
        loading: false,
        order: null,
        error: action.payload,
      };
      break;
    case LIST_ORDERS:
      state = {
        ...state,
        orders: null,
        loading: true,
        error: null,
      };
      break;
    case LIST_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: action.payload,
        error: null,
      };
      break;
    case LIST_ORDERS_FAIL:
      state = {
        ...state,
        loading: false,
        orders: null,
        error: action.payload,
      };
      break;
    case LAST_ORDERS:
      state = {
        ...state,
        orders: null,
        loading: true,
        error: null,
      };
      break;
    case LAST_ORDERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        orders: action.payload,
        error: null,
      };
      break;
    case LAST_ORDERS_FAIL:
      state = {
        ...state,
        loading: false,
        orders: null,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Order;
