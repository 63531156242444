import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// import translationDE from "./locales/de.json";
// import translationIT from "./locales/it.json";
// import translationRU from "./locales/ru.json";
// import translationES from "./locales/es.json";
import translationFR from "./locales/fr.json";
import translationEN from "./locales/en.json";

import { Settings } from "luxon";

// the translations
const resources = {
  // de: {
  //   translation: translationDE,
  // },
  // it: {
  //   translation: translationIT,
  // },
  // ru: {
  //   translation: translationRU,
  // },
  // es: {
  //   translation: translationES,
  // },
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};

const language = localStorage.getItem("i18nextLng");
if (!language) {
  localStorage.setItem("i18nextLng", "fr");
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "fr",
    fallbackLng: "en", // use en if detected lng is not available

    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

Settings.defaultLocale = localStorage.getItem("i18nextLng") || "fr";
i18n.on("languageChanged", function (lng) {
  Settings.defaultLocale = lng;
});

export default i18n;
