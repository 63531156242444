import PropTypes from "prop-types"
import React from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  FormFeedback,
  Input,
  Alert,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Import config
import { googleApp } from "../../config"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { Trans, withTranslation } from "react-i18next"
import i18n from "../../i18n/i18n"

// actions
import { loginUser, sendOTP } from "../../store/actions"

// import images
import profile from "assets/images/brand.png"
import logo from "assets/images/logo.png"

import LanguageDropdown from "../../components/Common/LanguageDropdown"

const Login = props => {
  //meta title
  document.title = i18n.t("login.title") + " | GreenPlay Innovation"

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
      type: "otp",
      i18n: localStorage.getItem("i18nextLng") || "fr",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(i18n.t("login.enterValidEmail"))
        .required(i18n.t("login.enterEmail")),
      password: Yup.string().required(i18n.t("login.enterOTP")),
    }),
    onSubmit: values => {
      dispatch(
        loginUser(
          values,
          new URLSearchParams(window.location.search).get("redirect"),
          props.router.navigate
        )
      )
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = { google: res, type }
      dispatch(
        loginUser(
          postData,
          new URLSearchParams(window.location.search).get("redirect"),
          props.router.navigate
        )
      )
    }
  }

  //for facebook and google authentication
  const googleResponse = response => {
    signIn(response.credential, "google")
  }

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  const scriptGIS = document.createElement("script")
  scriptGIS.src = "https://accounts.google.com/gsi/client"
  scriptGIS.addEventListener("load", () => {
    google.accounts.id.initialize({
      client_id: googleApp.CLIENT_ID,
      callback: googleResponse,
    })

    google.accounts.id.renderButton(
      document.getElementById("google-button-div"),
      {}
    )
  })
  document.body.appendChild(scriptGIS)

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                <Row>
                      <Col className="col-12" style={{ textAlign: "right" }}>
                        <LanguageDropdown />
                      </Col>
                    </Row>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"><Trans i18nKey="login.title"></Trans></h5>
                        <p><Trans i18nKey="login.description"></Trans></p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label for="email" className="form-label">
                          <Trans i18nKey="login.email" />
                        </Label>
                        <Input
                          name="email"
                          id="email"
                          className="form-control"
                          placeholder={i18n.t("login.enterEmail")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          autoComplete="on"
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className={props.OTPsent ? "mb-3" : "hidden"}>
                        <Label for="password" className="form-label">
                          <Trans i18nKey="login.OTP" />
                        </Label>
                        <Input
                          name="password"
                          id="password"
                          type="password"
                          placeholder={i18n.t("login.enterPassword")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className={props.OTPsent ? "mt-3 d-grid" : "hidden"}>
                        <button
                          className="btn btn-secondary btn-block"
                          type="submit"
                          disabled={props.loading}
                        >
                          {props.loading ? (
                            <i className="fas fa-circle-notch fa-spin"></i>
                          ) : (
                            <Trans i18nKey="login.login" />
                          )}
                        </button>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className={
                            "btn btn-block " +
                            (props.OTPsent ? "btn-primary" : "btn-secondary")
                          }
                          disabled={
                            props.loading || validation.values.email === ""
                          }
                          type="button"
                          onClick={() =>
                            sendOTP({
                              email: validation.values.email,
                              i18n: validation.values.i18n,
                            })
                          }
                        >
                          {props.loading ? (
                            <i className="fas fa-circle-notch fa-spin"></i>
                          ) : props.OTPsent ? (
                            <Trans i18nKey="login.resendOTP" />
                          ) : (
                            <span>
                              <i className="mdi mdi-lock-outline" />{" "}
                              <Trans i18nKey="login.sendOTP" />
                            </span>
                          )}
                        </button>
                      </div>

                      <div>
                        <h5 className="font-size-14 mt-5 mb-3 text-center">
                          <Trans i18nKey="login.or" />
                        </h5>
                        <div className="mt-3 d-grid text-center">
                          <div
                            id="google-button-div"
                            style={{ maxWidth: "400px", margin: "0 auto" }}
                          ></div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {
                  // <p>
                  //   Don&#39;t have an account ?{" "}
                  //   <Link to="/register" className="fw-medium text-primary">
                  //     {" "}
                  //     Signup now{" "}
                  //   </Link>{" "}
                  // </p>
                }
                <p>
                  <Trans i18nKey="footer.craftedWith" />{" "}
                  <i className="mdi mdi-heart text-danger" />{" "}
                  <Trans i18nKey="footer.by" /> © {new Date().getFullYear()} GreenPlay Innovation
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  router: PropTypes.object,
  apiError: PropTypes.any,
  error: PropTypes.any,
  OTPsent: PropTypes.bool,
  loading: PropTypes.any,
  loginUser: PropTypes.func,
  sendOTP: PropTypes.func,
  localities: PropTypes.any,
}

export default withRouter(withTranslation()(Login))
