import React, { Component } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { Link } from "react-router-dom"

//i18n
import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

// users
import noavatar from "../../assets/images/logo.png"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const authUser = JSON.parse(localStorage.getItem("authUser"))
    this.setState({ authUser })
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            {this.state.authUser ? (
              <span>
                <img
                  referrerPolicy="no-referrer"
                  className="rounded-circle header-profile-user"
                  src={
                    this.state.authUser.user.avatar
                      ? this.state.authUser.user.avatar
                      : noavatar
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = noavatar
                  }}
                  alt={i18n.t("profileMenu.avatar")}
                />{" "}
                <span className="d-none d-xl-inline-block ms-1">
                  {this.state.authUser.user.firstname !== ""
                    ? this.state.authUser.user.firstname +
                      " " +
                      this.state.authUser.user.lastname
                    : this.state.authUser.user.email}
                </span>
              </span>
            ) : (
              "nouser"
            )}

            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {
              // <Link
              //   to={
              //     this.state.authUser &&
              //     this.state.authUser.user &&
              //     this.state.authUser.user.type === "staff"
              //       ? "/profile"
              //       : "/account"
              //   }
              //   className="dropdown-item"
              // >
              //   <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              //   <Trans i18nKey="profileMenu.profile"></Trans>
              // </Link>
            }
            <Link to={"/account"} className="dropdown-item">
              <i className="bx bx-user font-size-16 align-middle me-1" />{" "}
              <Trans i18nKey="profileMenu.profile"></Trans>
            </Link>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />{" "}
              <Trans i18nKey="profileMenu.logout"></Trans>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(ProfileMenu)
