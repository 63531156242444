import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux" //
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { setStatus } from "../../../store/actions"

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// effect
import { Fade } from "react-awesome-reveal"

const Activate = props => {
  const [prepaid, setPrepaid] = useState(false)

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: localStorage.getItem("code") || "",
      // playersCount: 0,
      prepaidPrint: false,
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
    },
    validationSchema: Yup.object({
      // playersCount: Yup.number()
      //   .required(i18n.t("badge.playersCount.enterPlayersCount"))
      //   .min(1, i18n.t("badge.playersCount.enterMinQuantity"))
      //   .max(5, i18n.t("badge.playersCount.enterMaxQuantity")),
      password: Yup.string().required(i18n.t("badge.activate.enterPassword")),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: values => {
      values.prepaidPrint = prepaid
      localStorage.setItem("code", values.password)
      dispatch(setStatus(values, props.router.navigate))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container fluid>
            <Row>
              <div className="text-center">
                <Col className="mt-5 pb-5">
                  <h1 className="text-primary fw-semibold display-4 mt-5">
                    <Trans i18nKey="badge.activate.title"></Trans>
                  </h1>
                  <h4>
                    <Trans i18nKey="badge.activate.description"></Trans>
                  </h4>
                </Col>
              </div>
              <div className="mb-5">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  {error ? <Alert color="danger">{error}</Alert> : null}

                  {
                    // <div className="mb-3">
                    //   <Label className="form-label" for="playersCount">
                    //     <Trans i18nKey="badge.playersCount.playersCount"></Trans>
                    //   </Label>
                    //   <Input
                    //     name="playersCount"
                    //     id="playersCount"
                    //     value={validation.values.playersCount || ""}
                    //     type="number"
                    //     className="p-3 border-input"
                    //     placeholder={i18n.t("badge.playersCount.playersCount")}
                    //     autoFocus={true}
                    //     onChange={validation.handleChange}
                    //     onBlur={validation.handleBlur}
                    //     invalid={
                    //       validation.touched.playersCount &&
                    //       validation.errors.playersCount
                    //         ? true
                    //         : false
                    //     }
                    //   />
                    //   {validation.touched.playersCount &&
                    //   validation.errors.playersCount ? (
                    //     <FormFeedback type="invalid">
                    //       {validation.errors.playersCount}
                    //     </FormFeedback>
                    //   ) : null}
                    // </div>
                    // <div className="form-check form-switch form-switch-lg mb-3">
                    //   <Input
                    //     type="checkbox"
                    //     className="form-check-input"
                    //     name="prepaidPrint"
                    //     id="prepaidPrint"
                    //     value={validation.values.prepaidPrint || false}
                    //     onChange={validation.handleChange}
                    //     onBlur={validation.handleBlur}
                    //     invalid={
                    //       validation.touched.prepaidPrint &&
                    //       validation.errors.prepaidPrint
                    //         ? true
                    //         : false
                    //     }
                    //     // defaultChecked
                    //   />
                    //   <Label
                    //     className="form-check-label"
                    //     for="prepaidPrint"
                    //   >
                    //     <Trans i18nKey="badge.playersCount.prepaidPrint"></Trans>
                    //   </Label>
                    // </div>
                  }
                  <div className="mb-3">
                    <Label className="form-label" for="password">
                      <Trans i18nKey="badge.activate.password"></Trans>
                    </Label>
                    <Input
                      name="password"
                      id="password"
                      value={validation.values.password || ""}
                      type="password"
                      className="p-3 border-input"
                      placeholder={i18n.t("badge.activate.password")}
                      autoComplete="on"
                      disabled={props.loading}
                      autoFocus={true}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <Row>
                    <Col size={6}>
                      <div className="mt-3 ">
                        <button
                          className={
                            "btn btn-block btn-rounded btn-lg btn-primary"
                          }
                          type="submit"
                          onClick={() => setPrepaid(false)}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="badge.activate.submitWithoutPrint"></Trans>
                        </button>
                      </div>
                    </Col>
                    <Col size={6}>
                      <div className="mt-3 ">
                        <button
                          className={
                            "btn btn-block btn-rounded btn-lg float-end btn-success"
                          }
                          type="submit"
                          onClick={() => setPrepaid(true)}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="badge.activate.submitWithPrint"></Trans>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Row>
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Fade>
    </React.Fragment>
  )
}

Activate.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  playersCount: PropTypes.number,
  prepaidPrint: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading, playersCount, prepaidPrint } = state.Badge
  return { loading, playersCount, prepaidPrint }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Activate))
)
