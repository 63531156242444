import PropTypes from "prop-types"
import React from "react"

import { Container, Col } from "reactstrap"

//redux
//redux
import { connect, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

// bucket
import { ROOT_BUCKET } from "../../../helpers/url"

// actions
import { setStatus } from "../../../store/actions"

const Welcome = props => {
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <div className="vertical-center text-center">
              <Col className="mb-5 pb-5">
                <img  src={
                  ROOT_BUCKET +
                  "/config/" +
                  props.router.params.idPlace +
                  "/img/welcome.png"
                } alt="" className="img-fluid" />
              </Col>
              <Col className="mt-5 pb-5">
                <h1 className="text-primary fw-semibold display-4 mt-5">
                  <Trans i18nKey="badge.welcome.title"></Trans>
                </h1>
                <h4>
                  <Trans i18nKey="badge.welcome.description"></Trans>
                </h4>
              </Col>
            </div>
            <div className="mb-3 footer bg-transparent">
              <div className="d-grid">
                <button
                  className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    dispatch(
                      setStatus({
                        idPlace: props.router.params.idPlace || "",
                        idBadge: props.router.params.idBadge || 0,
                        token: props.router.params.token || "",
                        status: "playersCount",
                      },
                      props.router.navigate)
                    )
                  }}
                >
                  <Trans i18nKey="badge.welcome.submit"></Trans>
                </button>
              </div>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

Welcome.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { loading } = state.Badge
  return { loading }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Welcome))
)
