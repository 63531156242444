export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAIL = "GET_ORDER_FAIL";

export const LIST_ORDERS = "LIST_ORDERS";
export const LIST_ORDERS_SUCCESS = "LIST_ORDERS_SUCCESS";
export const LIST_ORDERS_FAIL = "LIST_ORDERS_FAIL";

export const LAST_ORDERS = "LAST_ORDERS";
export const LAST_ORDERS_SUCCESS = "LAST_ORDERS_SUCCESS";
export const LAST_ORDERS_FAIL = "LAST_ORDERS_FAIL";
