export const GET_BADGE = "GET_BADGE"
export const GET_BADGE_INFO = "GET_BADGE_INFO"
export const GET_BADGE_SUCCESS = "GET_BADGE_SUCCESS"
export const GET_BADGE_FAIL = "GET_BADGE_FAIL"

export const SET_STATUS = "SET_STATUS"
export const SET_STATUS_SUCCESS = "SET_STATUS_SUCCESS"
export const SET_STATUS_FAIL = "SET_STATUS_FAIL"

export const RESET = "RESET"
export const RESET_SUCCESS = "RESET_SUCCESS"
export const RESET_FAIL = "RESET_FAIL"

export const PRINT_SCORES = "PRINT_SCORES"
export const PRINT_SCORES_SUCCESS = "PRINT_SCORES_SUCCESS"
export const PRINT_SCORES_FAIL = "PRINT_SCORES_FAIL"

export const ACCESS_ADMIN = "ACCESS_ADMIN"
export const ACCESS_ADMIN_SUCCESS = "ACCESS_ADMIN_SUCCESS"
export const ACCESS_ADMIN_FAIL = "ACCESS_ADMIN_FAIL"

export const UPLOAD_PHOTO = "UPLOAD_PHOTO"
export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS"
export const UPLOAD_PHOTO_FAIL = "UPLOAD_PHOTO_FAIL"
export const UPLOAD_PHOTO_PROGRESS = "UPLOAD_PHOTO_PROGRESS";

export const SEND_EMAIL = "SEND_EMAIL"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL"

export const SEND_SURVEY = "SEND_SURVEY"
export const SEND_SURVEY_SUCCESS = "SEND_SURVEY_SUCCESS"
export const SEND_SURVEY_FAIL = "SEND_SURVEY_FAIL"

export const TAKE_CONTROL = "TAKE_CONTROL"
export const TAKE_CONTROL_SUCCESS = "TAKE_CONTROL_SUCCESS"
export const TAKE_CONTROL_FAIL = "TAKE_CONTROL_FAIL"
