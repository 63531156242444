import PropTypes from "prop-types"
import React from "react"

import {
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { setStatus } from "../../../store/actions"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// effect
import { Fade } from "react-awesome-reveal"

const PlayersName = props => {
  const dispatch = useDispatch()

  const playerNameValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      playerName: "",
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
      status: "game"
    },
    validationSchema: Yup.object({
      playerName: Yup.string()
        .required(i18n.t("badge.playerName.enterPlayerName"))
        .matches(
          /^[0-9a-zA-Z]*$/,
          i18n.t("badge.playerName.enterPlayerNameAlphaNumerical")
        )
        .max(7, i18n.t("badge.playerName.enterPlayerNameMaxLength")),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      values.playersCount = props.playersCount
      values.prepaidPrint = props.prepaidPrint
      values.players = props.players
      values.players.push(
        values.playerName.charAt(0).toUpperCase() + values.playerName.slice(1)
      )
      dispatch(setStatus(values,
        props.router.navigate))
      resetForm({
        values,
      })
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <Col className="mt-5 pb-5 text-center">
              <h3 className="text-primary fw-semibold display-5 mt-5">
                <Trans i18nKey="badge.playerName.title"></Trans>
              </h3>
              <h5>
                <Trans i18nKey="badge.playerName.description"></Trans>
              </h5>
            </Col>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  playerNameValidation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="mb-3">
                  <Label className="form-label" for="playerName">
                    <Trans i18nKey="badge.playerName.playerName"></Trans>
                    {props.players.length + 1}
                  </Label>
                  <Input
                    name="playerName"
                    id="playerName"
                    type="text"
                    className="p-3 border-input"
                    value={playerNameValidation.values.playerName || ""}
                    placeholder={
                      i18n.t("badge.playerName.playerName") +
                      parseInt(props.players.length + 1)
                    }
                    style={{ textTransform: "capitalize" }}
                    autoFocus={true}
                    // disabled={props.loading}
                    onChange={playerNameValidation.handleChange}
                    onBlur={playerNameValidation.handleBlur}
                    invalid={
                      playerNameValidation.touched.playerName &&
                      playerNameValidation.errors.playerName
                        ? true
                        : false
                    }
                  />
                  {playerNameValidation.touched.playerName &&
                  playerNameValidation.errors.playerName ? (
                    <FormFeedback type="invalid">
                      {playerNameValidation.errors.playerName}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mt-3 d-grid">
                  <button
                    className={
                      "btn btn-block btn-rounded btn-lg " +
                      (playerNameValidation.isValid && playerNameValidation.dirty
                        ? "btn-primary"
                        : "btn-disabled-light")
                    }
                    type="submit"
                    disabled={
                      props.loading ||
                      !(
                        playerNameValidation.isValid &&
                        playerNameValidation.dirty
                      )
                    }
                  >
                    <Trans i18nKey="badge.playerName.submit"></Trans>
                  </button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

PlayersName.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  playersCount: PropTypes.number,
  prepaidPrint : PropTypes.bool,
  players: PropTypes.array,
}

const mapStateToProps = state => {
  const { loading, playersCount, prepaidPrint, players } = state.Badge
  return { loading, playersCount, prepaidPrint, players }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(PlayersName))
)
