import PropTypes from "prop-types"
import React from "react"

import { Container, Col } from "reactstrap"

//redux
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

// bucket
import { ROOT_BUCKET } from "../../../helpers/url"


const Again = props => {

  return (
    <React.Fragment>
      <Fade>
          <Container>
            <div className="vertical-center text-center">
              <Col>
                <img
                  src={
                    ROOT_BUCKET +
                    "/config/" +
                    props.router.params.idPlace +
                    "/img/again.png"
                  }
                  alt=""
                  className="img-fluid"
                />
              </Col>
              <Col>
                <h3 className="text-primary fw-semibold mb-3">
                  <Trans i18nKey="badge.again.title"></Trans>
                </h3>
                <h5 className="p-5">
                  <Trans i18nKey="badge.again.description"></Trans><br/>
                  <Trans i18nKey="badge.again.seeYouSoon"></Trans>
                </h5>
              </Col>
            </div>
            
          </Container>
      </Fade>
    </React.Fragment>
  )
}

Again.propTypes = {
  router: PropTypes.object
 
}

export default withRouter(
  (withTranslation()(Again))
)
