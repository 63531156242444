import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Container, Row, Col, Modal, Card, CardBody } from "reactstrap"

//redux
import { connect } from "react-redux" // useDispatch
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// takePhoto
import TakePhoto from "./TakePhoto"

const Scores = props => {
  const ranking = props.scores || []

  // creer le design complet du podium meme si il y a moins de 3 joueurs
  // if (ranking.length < 3) {
  //   for (let idxRank = ranking.length; idxRank < 3; idxRank++) {
  //     ranking[idxRank] = {
  //       playerIndex: idxRank,
  //       playerName: "-",
  //       score: 0,
  //     }
  //   }
  // }

  // creer le design "vide" du podium 
  if (ranking.length <= 0) {
    for (let idxRank = 0; idxRank < props.players.length; idxRank++) {
      ranking[idxRank] = {
        playerIndex: idxRank,
        playerName: "-",
        score: 0,
      }
    }
  }

  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  useEffect(() => {
    if (props.show) tog_center()
  }, [])

  // const dispatch = useDispatch()

  // on fait le podium
  const rank = [1, 0, 2]
  const style = ["second", "first", "third"]
  const podium = ranking.map((valPodium, indexPodium) => {
    if (rank[indexPodium] !== undefined)
      return (
        <Col xs={4} key={indexPodium} className="text-center">
          <div className={"podium " + style[indexPodium]}>
            <div>
              <button className="btn btn-light btn-rounded">
                {rank[indexPodium] + 1}°
              </button>
            </div>
            <div className="name">
              {ranking[rank[indexPodium]]
                ? ranking[rank[indexPodium]].playerName
                : "-"}
            </div>
            <div className="points">
              {ranking[rank[indexPodium]]
                ? ranking[rank[indexPodium]].score
                : "-"}{" "}
              {ranking[rank[indexPodium]] ? (
                ranking[rank[indexPodium]].score > 1 ? (
                  <Trans i18nKey="badge.scores.pts"></Trans>
                ) : (
                  <Trans i18nKey="badge.scores.pt"></Trans>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      )
    return (
      <div key={indexPodium} className={indexPodium === 3 ? "mt-4" : ""}>
        <Card className="podium-game">
          <CardBody>
            <Row>
              <Col>
                <h5 className="mb-0 mt-1">
                  <Row>
                    <Col xs={2}>{indexPodium + 1}°</Col>
                    <Col xs={5}>{ranking[indexPodium].playerName}</Col>
                    <Col xs={5}>
                      <span className="float-end">
                        <b>
                          {ranking[indexPodium].score}{" "}
                          {ranking[indexPodium].score > 1 ? (
                            <Trans i18nKey="badge.scores.points"></Trans>
                          ) : (
                            <Trans i18nKey="badge.scores.point"></Trans>
                          )}
                        </b>
                      </span>
                    </Col>
                  </Row>
                </h5>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    )
  })

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={props.show}
          size="xl"
          centered
          backdrop={"static"}
          fullscreen={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <Trans i18nKey="badge.scores.title"></Trans>
            </h5>
            <button
              type="button"
              onClick={() => {
                props.close()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              disabled={props.loading}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="page-content">
              <Container fluid>
                <Row>{podium}</Row>
              </Container>
            </div>{" "}
          </div>

          {props.takePhoto ? (
            <div className="modal-footer modal-footer-grid">
              {props.takePhoto ? (
                <Row className="text-center">
                  <h4 className="text-primary fw-semibold mb-3">
                    <Trans i18nKey="badge.takePhoto.title"></Trans>
                  </h4>
                  <h6>
                    <Trans i18nKey="badge.takePhoto.description"></Trans>
                  </h6>
                </Row>
              ) : (
                ""
              )}
              <TakePhoto />
            </div>
          ) : (
            ""
          )}
        </Modal>
      </div>
    </React.Fragment>
  )
}

Scores.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  show: PropTypes.bool,
  close: PropTypes.func,
  takePhoto: PropTypes.bool,
  scores: PropTypes.array,
  players : PropTypes.array
}

const mapStateToProps = state => {
  const { loading, scores, players } = state.Badge
  return {
    loading,
    scores,
    players
  }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Scores))
)
