export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL"

export const SEND_OTP_USER = "SEND_OTP_USER"
export const SEND_OTP_USER_SUCCESS = "SEND_OTP_USER_SUCCESS"
export const SEND_OTP_USER_FAIL = "SEND_OTP_USER_FAIL"

export const GET_LOCALITIES = "GET_LOCALITIES"
export const GET_LOCALITIES_SUCCESS = "GET_LOCALITIES_SUCCESS"
export const GET_LOCALITIES_FAIL = "GET_LOCALITIES_FAIL"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAIL = "LOGOUT_USER_FAIL"
