import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Public pages
import Badge from "../pages/Public/Badge/Index"
import Home from "../pages/Public/Home/Index"

// Clients pages
import ClientHome from "../pages/Clients/Index"
import ClientProfile from "../pages/Clients/Profile"
// import Settings from "../pages/Clients/Settings"
import PlaceHardware from "../pages/Clients/Hardware"
import PlaceOrders from "../pages/Clients/Orders"
import PlaceInvoicing from "../pages/Clients/Invoicing"
import PlaceStripe from "../pages/Clients/Stripe"

// Back OFfice pages
import Dashboard from "../pages/BackOffice/Dashboard";
import BackOfficePlaces from "../pages/BackOffice/Places";
import BackOfficeHardware from "../pages/BackOffice/Hardware";
import Users from "../pages/BackOffice/Users";
import BackOfficeOrders from "../pages/BackOffice/Orders";
import BackOfficeProfile from "../pages/BackOffice/Profile";

const authProtectedBackOfficeRoutes = [
  { path: "/profile", component: <BackOfficeProfile/>},
  { path: "/dashboard", component: <Dashboard/> },
  { path: "/places", component: <BackOfficePlaces/> },
  { path: "/hardware", component: <BackOfficeHardware/> },
  { path: "/users", component: <Users/> },
  { path: "/orders", component: <BackOfficeOrders/> },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const authProtectedClientRoutes = [
  { path: "/account", component: <ClientProfile /> },
  { path: "/place/:idPlace", component: <ClientHome /> },
  // { path: "/place/:idPlace/settings", component: <Settings/> },
  { path: "/place/:idPlace/hardware", component: <PlaceHardware /> },
  { path: "/place/:idPlace/orders", component: <PlaceOrders /> },
  { path: "/place/:idPlace/invoicing", component: <PlaceInvoicing /> },
  { path: "/place/:idPlace/stripe/:type", component: <PlaceStripe /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/login" />,
  },
]

const badgeRoutes = [
  { path: ":idBadge/:idPlace/:token", component: <Badge /> },
  { path: ":idBadge", component: <Badge /> },
  { path: "/", component: <Home /> },
  {
    path: "*",
    component: <Navigate to="/" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

const showCaseRoutes = [
  // { path: "/", component: Home },
  // {
  //   path: "*",
  //   component: <Navigate to="/" />,
  // },
]

const publicHelpRoutes = [
  // { path: "/notFound", component: Page404 },
  // { path: "/:idComputer", component: Help },
  // { path: "/", component: Help },
  // // this route should be at the end of all other routes
  // {
  //   path: "*",
  //   component:  <Navigate to="/" />,
  // },
]

export {
  authProtectedBackOfficeRoutes,
  authProtectedClientRoutes,
  publicRoutes,
  badgeRoutes,
  publicHelpRoutes,
  showCaseRoutes,
}
