import PropTypes from "prop-types"
import React, { useState } from "react"

import { Row, Card, CardBody, Col, Container } from "reactstrap"

//redux
import { connect, useDispatch } from "react-redux" // , useDispatch
import withRouter from "components/Common/withRouter"

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// i18n
import { Trans, withTranslation } from "react-i18next"

import Rules from "./Rules"
import Hole from "./Hole"
import Scores from "./Scores"

// actions
import { setStatus } from "../../../store/actions"

// effect
import { Fade } from "react-awesome-reveal"

const Game = props => {
  const dispatch = useDispatch()

  // let badge = JSON.parse(localStorage.getItem("badge"))

  let gameInit =
    Object.keys(props.game).length === 0
      ? { players: {}, holes: [] }
      : props.game

  if (Object.keys(gameInit.players).length <= 0) {
    for (
      let indexPlayer = 0;
      indexPlayer < props.players.length;
      indexPlayer++
    ) {
      const tabGame = Array(props.green.length)
        .join()
        .split(",")
        .map(
          function () {
            return ""
          },
          { i: 0 }
        )
      gameInit["players"]["player_" + indexPlayer] = tabGame
    }

    for (let indexHole = 0; indexHole < props.green.length; indexHole++)
      gameInit["holes"][indexHole] = -1

    // localStorage.setItem("game", JSON.stringify(gameInit))
  }

  const [game, setGame] = useState(gameInit)

  // const dispatch = useDispatch()

  const updateGame = () => {
    setGame(game)
    updateRanking()
    const sum = game.holes.reduce((partialSum, a) => partialSum + a, 0)
    if (sum === game.holes.length) {
      setTakePhoto(true)
      setShowScores(true)
    }
  }

  const updateRanking = () => {
    let rankingUpdate = []

    for (
      let indexPlayer = 0;
      indexPlayer < props.players.length;
      indexPlayer++
    ) {
      rankingUpdate.push({
        playerIndex: indexPlayer,
        playerName: props.players[indexPlayer],
        score: parseInt(
          game["players"]["player_" + indexPlayer].reduce(
            (a, v) => (a = parseInt(a + v)),
            0
          )
        ),
      })
    }

    rankingUpdate = rankingUpdate.sort(function (a, b) {
      return a.score - b.score
    })

    // localStorage.setItem("scores", JSON.stringify(rankingUpdate))
    setRanking(rankingUpdate)
    dispatch(
      setStatus({
        idPlace: props.router.params.idPlace || "",
        idBadge: props.router.params.idBadge || 0,
        token: props.router.params.token || "",
        status: "game",
        i18n: localStorage.getItem("i18nextLng") || "fr",
        game: game,
        scores: rankingUpdate,
      },
      props.router.navigate)
    )
  }

  // const onEndGameClick = () => {
  //   const values = {
  //     idPlace: props.router.params.idPlace,
  //     idBadge: props.router.params.idBadge,
  //     token: props.router.params.token,
  //     game: localStorage.getItem("game"),
  //     scores: localStorage.getItem("scores"),
  //     status: "congrats",
  //   }
  //   dispatch(setStatus(values,
   //           props.router.navigate))
  // }

  const [activeHole, setActiveHole] = useState(-1)
  const [showHole, setShowHole] = useState(false)

  // on affiche les regles en automatique seulement si on n'a pas commencé la partie ! (car ca signifie qu'on ne leur a jamais montré les regles auparavent)
  const firstStart = false
  // gameInit.holes.filter(hole => hole != -1).length > 0 ? false : true
  const [showRules, setShowRules] = useState(firstStart)

  // let rankingInit = props.scores.length <= 0 ? {} : props.scores

  const [ranking, setRanking] = useState(props.scores || [])
  const [takePhoto, setTakePhoto] = useState(false)
  const [showScores, setShowScores] = useState(false)

  return (
    <React.Fragment>
      <Fade>
        <Container fluid>
          <div className="page-content text-center mb-3">
            <Card
              onClick={() => {
                setTakePhoto(false)
                setShowScores(true)
              }}
              className="podium-game"
            >
              <CardBody>
                {ranking.length > 0 ? (
                  <Row className="text-center">
                    {ranking.map((scoreValue, scoreIndex) =>
                      scoreIndex >= 3 ? (
                        ""
                      ) : (
                        <Col
                          key={scoreIndex}
                          xs={
                            props.players.length >= 3
                              ? "4"
                              : props.players.length === 2
                              ? "6"
                              : "12"
                          }
                        >
                          <h4 className="mb-0 mt-1">
                            {scoreIndex + 1}°
                            <br />
                            <span className="fs-6 text text-secondary">
                              {scoreValue.playerName}
                              <br />({scoreValue.score})
                            </span>
                          </h4>
                        </Col>
                      )
                    )}
                  </Row>
                ) : (
                  <Row className="text-center">
                    {props.players.map((scoreValue, scoreIndex) =>
                      scoreIndex >= 3 ? (
                        ""
                      ) : (
                        <Col
                          key={scoreIndex}
                          xs={
                            props.playersCount >= 3
                              ? "4"
                              : props.playersCount === 2
                              ? "6"
                              : "12"
                          }
                        >
                          <h4 className="mb-0 mt-1">
                            {scoreIndex + 1}°
                            <br />
                            <span className="fs-6 text text-secondary">-</span>
                          </h4>
                        </Col>
                      )
                    )}
                  </Row>
                )}
              </CardBody>
            </Card>

            {props.green &&
              props.green.map((val, index) => {
                const hole = parseInt(index + 1)
                // trous positionnés sur la gauche (ex: trou n°1)
                if (index % 6 === 0) {
                  return (
                    <Row
                      key={index}
                      className={index === 0 ? "" : "game-margin"}
                    >
                      <Col xs={3} className="">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index] === -1
                              ? " btn-outline-dark"
                              : game.holes[index] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole}</span>
                          )}{" "}
                        </button>
                      </Col>
                    </Row>
                  )
                }

                // trous positionnés à droite (ex: trou n°4)
                if (index % 6 === 3) {
                  return (
                    <Row key={index} className="game-margin">
                      <Col xs={3} className="offset-9">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index] === -1
                              ? " btn-outline-dark"
                              : game.holes[index] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole}</span>
                          )}{" "}
                        </button>
                      </Col>
                    </Row>
                  )
                }

                // trous positionnés sur la ligne horizontale de droite à gauche
                if (index % 6 === 5) {
                  const line = (
                    <Row key={index} className="game-margin">
                      {index < props.green.length - 1 ? (
                        <Col xs={3} className="wrap-top-left">
                          {" "}
                        </Col>
                      ) : (
                        <Col xs={3} className="">
                          {" "}
                        </Col>
                      )}
                      <Col xs={3} className="wrap-horizontal">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index] === -1
                              ? " btn-outline-dark"
                              : game.holes[index] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole}</span>
                          )}{" "}
                        </button>
                      </Col>
                      <Col xs={3} className="">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index - 1] === -1
                              ? " btn-outline-dark"
                              : game.holes[index - 1] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index - 1)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index - 1] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole - 1}</span>
                          )}{" "}
                        </button>
                      </Col>
                      <Col xs={3} className="wrap-bottom-right bg-z-index">
                        {" "}
                      </Col>
                    </Row>
                  )

                  return line
                }

                // 1 seul trous positionné sur la ligne horizontale de droite à gauche (c'est donc le dernier trou)
                if (index % 6 === 4 && index === props.green.length - 1) {
                  return (
                    <Row key={index} className="game-margin">
                      <Col xs={3}></Col>
                      <Col xs={3}></Col>
                      <Col xs={3} className="">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index] === -1
                              ? " btn-outline-dark"
                              : game.holes[index] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole}</span>
                          )}{" "}
                        </button>
                      </Col>
                      <Col xs={3} className="wrap-top-right">
                        {" "}
                      </Col>
                    </Row>
                  )
                }

                // trous positionnés sur la ligne horizontale de gauche à droite
                if (index % 6 === 1) {
                  const line = (
                    <Row key={index} className="game-margin">
                      <Col xs={3} className="wrap-bottom-left bg-z-index">
                        {" "}
                      </Col>
                      <Col xs={3} className="wrap-horizontal">
                        <button
                          className={
                            "btn btn-rounded btn-hole " +
                            (game.holes[index] === -1
                              ? " btn-outline-dark"
                              : game.holes[index] === 0
                              ? " btn-dark"
                              : "btn-success")
                          }
                          onClick={() => {
                            setActiveHole(index)
                            setShowHole(true)
                          }}
                        >
                          {" "}
                          {game.holes[index] === 1 ? (
                            <i className="fas fa-check" />
                          ) : (
                            <span>{hole}</span>
                          )}{" "}
                        </button>
                      </Col>
                      {index < props.green.length - 1 ? (
                        <Col xs={3} className="">
                          <button
                            className={
                              "btn btn-rounded btn-hole " +
                              (game.holes[index + 1] === -1
                                ? " btn-outline-dark"
                                : game.holes[index + 1] === 0
                                ? " btn-dark"
                                : "btn-success")
                            }
                            onClick={() => {
                              setActiveHole(index + 1)
                              setShowHole(true)
                            }}
                          >
                            {" "}
                            {game.holes[index + 1] === 1 ? (
                              <i className="fas fa-check" />
                            ) : (
                              <span>{hole + 1}</span>
                            )}{" "}
                          </button>
                        </Col>
                      ) : (
                        ""
                      )}
                      {index < props.green.length - 1 ? (
                        <Col xs={3} className="wrap-top-right">
                          {" "}
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  )
                  return line
                }

                return ""
              })}
          </div>
          <Rules show={showRules} close={() => setShowRules(false)} />
          <div className="mb-3 footer bg-transparent">
            <div className="d-grid">
              <button
                className={
                  "btn btn-rounded btn-block btn-lg fw-semibold " +
                  (props.loading || props.scores.length <= 0
                    ? "btn-disabled-light"
                    : "btn-primary")
                }
                type="button"
                disabled={props.loading || props.scores.length <= 0}
                onClick={() => {
                  setTakePhoto(true)
                  setShowScores(true)
                }}
              >
                <Trans i18nKey="badge.game.endGame"></Trans>
              </button>
            </div>
          </div>
          <Hole
            game={game}
            activeHole={activeHole}
            show={showHole}
            close={() => setShowHole(false)}
            updateGame={updateGame}
          />
          <Scores
            show={showScores}
            close={() => setShowScores(false)}
            takePhoto={takePhoto}
          />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Container>
      </Fade>
    </React.Fragment>
  )
}

Game.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  playersCount: PropTypes.number,
  players: PropTypes.array,
  green: PropTypes.array,
  game: PropTypes.object,
  scores: PropTypes.array,
}

const mapStateToProps = state => {
  const { loading, status, playersCount, players, green, game, scores } =
    state.Badge
  return {
    loading,
    status,
    playersCount,
    players,
    green,
    game,
    scores,
  }
}

export default withRouter(connect(mapStateToProps, {})(withTranslation()(Game)))
