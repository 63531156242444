import PropTypes from "prop-types"
import React from "react"
// import { useSelector } from "react-redux"
import { Routes, Route } from "react-router-dom"
// import { layoutTypes } from "./constants/layout"
// Import Routes all
import {
  authProtectedBackOfficeRoutes,
  authProtectedClientRoutes,
  publicRoutes,
  badgeRoutes,
  publicHelpRoutes,
  showCaseRoutes,
} from "./routes"

// layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
import HorizontalBadgeLayout from "./components/HorizontalBadgeLayout"
import HorizontalClientLayout from "./components/HorizontalClientLayout"
import VerticalBackOfficeLayout from "./components/VerticalBackOfficeLayout"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

// Activating fake backend
// fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

// const getLayout = (layoutType) => {
//   let Layout = HorizontalBadgeLayout;
//   switch (layoutType) {
//     case layoutTypes.VERTICAL:
//       Layout = VerticalLayout;
//       break;
//     case layoutTypes.HORIZONTAL:
//       Layout = HorizontalBadgeLayout;
//       break;
//     default:
//       break;
//   }
//   return Layout;
// };
const getSubDomain = () => {
  const host = window.location.host
  const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2)
  if (arr.length > 0) return arr[0]
  return "" // host.includes("localhost") ? "web" : ""
}

const App = () => {
  // const { layoutType } = useSelector((state) => ({
  //   layoutType: state.Layout.layoutType,
  // }));

  return (
    <React.Fragment>
      <Routes>
        {((getSubDomain() == "" &&
          window.location.host.includes("localhost")) ||
          getSubDomain() == "web") &&
          authProtectedBackOfficeRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={
                  <VerticalBackOfficeLayout>
                    {route.component}
                  </VerticalBackOfficeLayout>
                }
                component={route.component}
                key={idx}
                isAuthProtected={2}
                subDomain="web"
                exact
              />
            )
          })}

        {((getSubDomain() == "" &&
          window.location.host.includes("localhost")) ||
          getSubDomain() == "web") &&
          authProtectedClientRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={
                  <HorizontalClientLayout>
                    {route.component}
                  </HorizontalClientLayout>
                }
                key={idx}
                exact={true}
                isAuthProtected={1}
                subDomain="web"
              />
            )
          })}

        {((getSubDomain() == "" &&
          window.location.host.includes("localhost")) ||
          getSubDomain() == "web") &&
          publicRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
                isAuthProtected={0}
                subDomain="web"
              />
            )
          })}

        {((getSubDomain() == "" &&
          window.location.host.includes("localhost")) ||
          getSubDomain() == "badge") &&
          badgeRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <HorizontalBadgeLayout>{route.component}</HorizontalBadgeLayout>
              }
              key={idx}
              exact={true}
              isAuthProtected={0}
              subDomain="badge"
            />
          ))}

        {getSubDomain() == "www" &&
          showCaseRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={0}
                subDomain=""
                exact
              />
            )
          })}
        {getSubDomain() == "help" &&
          publicHelpRoutes.map((route, idx) => {
            return (
              <Route
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={0}
                subDomain="help"
                exact
              />
            )
          })}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
