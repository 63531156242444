import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

import { Helmet, HelmetProvider } from "react-helmet-async"

import Breadcrumbs from "../../components/Common/Breadcrumb"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../assets/scss/custom/plugins/_datatables.scss"

//store
import { listHardware, listPlaces, editHardware } from "../../store/actions"

//i18n
import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

import { ROOT_BUCKET } from "../../helpers/url"

const Hardware = props => {
  const [currentOpenedHardware, setCurrentOpenedHardware] = useState({})
  const [modalEditIsOpened, setModalEditIsOpened] = useState(false)

  useEffect(() => {
    props.listHardware(props.router.navigate)
    props.listPlaces(props.router.navigate)
  }, [])

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.editUpdated !== props.editUpdated &&
  //     props.editUpdated === true
  //   ) {
  //     setState({ modalEditIsOpened: false });
  //     props.listHardware(props.history);
  //   }
  // }

  const togModalEdit = () => {
    setModalEditIsOpened(!modalEditIsOpened)
  }

  const handleId = cell => {
    return <b>#{cell}</b>
  }

  const handlePlace = (cell, row) => {
    return (
      <div>
        <img
          src={ROOT_BUCKET + "/" + row.logo.replace("logo", "img/logo")}
          style={{ height: "26px" }}
        ></img>
      </div>
    )
  }

  const columns = [
    {
      dataField: "idComputer",
      text: i18n.t("hardware.id"),
      formatter: handleId,
      sort: true,
      searchable: true,
    },
    {
      dataField: "place",
      text: i18n.t("hardware.place"),
      formatter: handlePlace,
      sort: true,
      searchable: true,
    },
    {
      dataField: "pin",
      text: i18n.t("hardware.pin"),
      sort: false,
      searchable: false,
    },
  ]

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: (props.hardwares && props.hardwares.length) || 0, // replace later with size(customers),
    custom: true,
  }

  // Select All Button operation
  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    classes: "bg-primary bg-soft",
  }
  const rowEvents = {
    onClick: (e, row) => {
      setCurrentOpenedHardware(row)
      togModalEdit()
    },
    // onMouseEnter: (e, row, rowIndex) => {
    //   console.log(`enter on row with index: ${rowIndex}`);
    // }
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <HelmetProvider>
          <Helmet>
            <title>{i18n.t("sidebar.hardware") + " | GreenPlay Innovation"}</title>
          </Helmet>
        </HelmetProvider>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={"GreenPlay Innovation"}
            breadcrumbItem={props.t("sidebar.hardware")}
          />
          {props.hardwares ? (
            <Row className="justify-content-center">
              <Col lg="12">
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <div className="p-2 mb-5">
                      <h5 className="mt-3 mb-3">
                        <Trans i18nKey="sidebar.hardware" />
                      </h5>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="idComputer"
                        columns={columns}
                        data={props.hardwares}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="idComputer"
                            columns={columns}
                            data={props.hardwares}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                          placeholder={i18n.t("place.search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"idComputer"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        hover={true}
                                        defaultSorted={defaultSorted}
                                        selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap table-check"
                                        }
                                        headerWrapperClasses={"table-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                        noDataIndication={
                                          <Trans i18nKey="place.emptyListHardware"></Trans>
                                        }
                                        rowEvents={rowEvents}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {
                                      // <div className="d-inline">
                                      //   <SizePerPageDropdownStandalone
                                      //     {...paginationProps}
                                      //   />
                                      // </div>
                                    }
                                    <div className="text-md-right ms-auto">
                                      <div className="pagination pagination-rounded justify-content-end">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                      <br />
                      <br />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Modal
                isOpen={modalEditIsOpened}
                toggle={togModalEdit}
                centered={true}
                backdrop="static"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    pin: currentOpenedHardware.pin || "0000",
                    idPlace: currentOpenedHardware.idPlace || 0,
                    idComputer: currentOpenedHardware.idComputer || 0,
                  }}
                  validationSchema={Yup.object().shape({
                    pin: Yup.string()
                      .required(i18n.t("place.enterPin"))
                      .matches("^[0-9]{4}$", i18n.t("place.enter4digits")),
                  })}
                  onSubmit={values => {
                    props.editHardware(values, props.router.navigate)
                  }}
                >
                  {({ handleBlur, setFieldValue, errors, touched }) => (
                    <Form className="form-horizontal">
                      <ModalHeader>
                        <span className="text-warning">
                          <Trans i18nKey="hardware.editTitle"></Trans>
                        </span>
                        <button
                          type="button"
                          onClick={() => setModalEditIsOpened(false)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={props.loading}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <div className="mt-3 mb-3">
                          <Trans i18nKey="hardware.editDescription"></Trans>
                        </div>
                        <div className="text-center">
                          <div className="mb-1">
                            <i
                              className="bx bx bx-printer display-6 text-primary"
                              style={{
                                lineHeight: "1",
                              }}
                            />
                          </div>
                          <h6>
                            <Trans i18nKey="place.printStation"></Trans>
                          </h6>
                          <p>
                            <Trans i18nKey="place.id"></Trans> :{" "}
                            {currentOpenedHardware.idComputer}
                          </p>
                        </div>
                        <div className="mb-3">
                          <Label for="pin" className="form-label">
                            <Trans i18nKey={"place.yourPin"} /> *
                          </Label>
                          <Field
                            name="pin"
                            label={i18n.t("place.yourPin")}
                            type="string"
                            disabled={props.loading}
                            className={
                              "form-control" +
                              (errors.pin && touched.pin ? " is-invalid" : "")
                            }
                            onChange={e => {
                              const value = e.target.value || ""
                              setFieldValue("pin", value)
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="pin"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="idPlace" className="form-label">
                            <Trans i18nKey="hardware.place" /> *
                          </Label>
                          <Field
                            name="idPlace"
                            as="select"
                            className={
                              "form-control" +
                              (errors.idPlace && touched.idPlace
                                ? " is-invalid"
                                : "")
                            }
                            disabled={props.loading}
                          >
                            {props.places.map(place => {
                              return (
                                <option
                                  value={place.idPlace}
                                  key={place.idPlace}
                                >
                                  {place.name}
                                </option>
                              )
                            })}
                          </Field>
                          <ErrorMessage
                            name="idPlace"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togModalEdit}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.cancel"></Trans>
                        </button>
                        <button
                          className="btn btn-warning"
                          type="submit"
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.update"></Trans>
                        </button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

Hardware.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  hardwares: PropTypes.array,
  places: PropTypes.array,
  editUpdated: PropTypes.bool,
  listHardware: PropTypes.func,
  listPlaces: PropTypes.func,
  editHardware: PropTypes.func,
}

const mapStateToProps = state => {
  const { error, loading, hardwares, editUpdated } = state.Hardware
  const { places } = state.Place
  return { error, loading, hardwares, editUpdated, places }
}

export default connect(mapStateToProps, {
  listHardware,
  listPlaces,
  editHardware,
})(withRouter(withTranslation()(Hardware)))
