import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Badge
import Badge from "./badge/reducer";

// Authentication
import Login from "./authentication/login/reducer";
import Profile from "./authentication/profile/reducer";

// Clients space
import Place from "./place/reducer";

// Back Office space
import Hardware from "./hardware/reducer";
import User from "./user/reducer";
import Order from "./order/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Badge,
  Login,
  Profile,
  Place,
  Hardware,
  User,
  Order,
});

export default rootReducer;
