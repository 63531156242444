import PropTypes from "prop-types"
import React, { useState } from "react"

import { Col, Container, Form, Alert } from "reactstrap"

// Rating Plugin
import { Rating } from "react-simple-star-rating"

//redux
import { connect, useSelector, useDispatch } from "react-redux" //
import withRouter from "components/Common/withRouter"

// actions
import { sendSurvey } from "../../../store/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// i18n
import i18n from "../../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

const Survey = props => {
  const [answers, setAnswers] = useState(
    props.survey[i18n.language].map(() => 0)
  )
  const [completeSurvey, setCompleteSurvey] = useState(false)
  // Catch Rating value
  const handleRating = (rate, index) => {
    const updateAnswers = answers
    updateAnswers[index] = rate
    setAnswers(updateAnswers)
    setCompleteSurvey(
      answers.filter(val => (val > 0 ? false : true)).length <= 0 ? true : false
    )
  }
  const dispatch = useDispatch()

  const sendSurveyValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
      i18n: localStorage.getItem("i18nextLng") || "fr",
      survey: props.survey[i18n.language] || [],
      completeSurvey: completeSurvey || false,
      status: "thanks",
    },
    validationSchema: Yup.object({
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
      completeSurvey: Yup.boolean().isTrue(),
    }),
    onSubmit: values => {
      values.survey = props.survey[i18n.language].map(
        (valSurvey, indexSuvey) => {
          return { ...valSurvey, answer: answers[indexSuvey] }
        }
      )
      // console.log(values)
      dispatch(sendSurvey(values))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <Col className="mt-5 pb-2 text-center">
              <h3 className="text-primary fw-semibold display-5 mt-5">
                <Trans i18nKey="badge.sendSurvey.title"></Trans>
              </h3>
              <h5>
                <Trans i18nKey="badge.sendSurvey.description"></Trans>
              </h5>
            </Col>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  sendSurveyValidation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}
                {props.survey[i18n.language].map((valSurvey, indexSuvey) => {
                  return (
                    <Col key={indexSuvey}>
                      <div className="p-3 text-center">
                        <h5 className="font-size-15">{valSurvey.title}</h5>
                        <h5 className="font-size-12">{valSurvey.question}</h5>
                        <div className="d-flex justify-content-center align-items-center">
                          <Rating
                            iconsCount={5}
                            fillColor="#1A3A3F"
                            size={28}
                            className="rating-symbol-background"
                            id={indexSuvey}
                            initialValue={
                              props.survey[i18n.language][indexSuvey][
                                "value"
                              ] || 0
                            }
                            onClick={value => handleRating(value, indexSuvey)}
                          />
                        </div>
                      </div>
                    </Col>
                  )
                })}
                <div className="mt-3 mb-4 d-grid">
                  <button
                    className={
                      "btn btn-block btn-rounded btn-lg " +
                      (completeSurvey ? "btn-primary" : "btn-disabled-light")
                    }
                    type="submit"
                    disabled={props.loading || !completeSurvey}
                  >
                    {props.loading ? (
                      <i className="bx bx-loader bx-spin"></i>
                    ) : (
                      <Trans i18nKey="badge.sendSurvey.submit"></Trans>
                    )}
                  </button>
                </div>
              </Form>
              <div className="text-center">
                <button
                  className={"btn btn-rounded btn-sm btn-default btn-skip"}
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    dispatch(
                      sendSurvey({
                        idPlace: props.router.params.idPlace,
                        idBadge: props.router.params.idBadge,
                        token: props.router.params.token,
                        i18n: localStorage.getItem("i18nextLng"),
                        survey: props.survey[i18n.language],
                        completeSurvey: false,
                        status: "thanks",
                      })
                    )
                  }}
                >
                  {props.loading ? (
                    <i className="bx bx-loader bx-spin"></i>
                  ) : (
                    <Trans i18nKey="badge.sendSurvey.skip"></Trans>
                  )}
                </button>
              </div>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

Survey.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  survey: PropTypes.object,
}

const mapStateToProps = state => {
  const { loading, status, survey } = state.Badge
  return { loading, status, survey }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Survey))
)
