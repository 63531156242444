import PropTypes from "prop-types"
import React from "react"

import {
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { setStatus } from "../../../store/actions"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// effect
import { Fade } from "react-awesome-reveal"

const PlayersCount = props => {
  const dispatch = useDispatch()

  const playersCountValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      playersCount: "",
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
      status: "playersNames",
    },
    validationSchema: Yup.object({
      playersCount: Yup.number()
        .required(i18n.t("badge.playersCount.enterPlayersCount"))
        .min(1, i18n.t("badge.playersCount.enterMinQuantity"))
        .max(8, i18n.t("badge.playersCount.enterMaxQuantity")),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: (values, { resetForm }) => {
      values.prepaidPrint = props.prepaidPrint
      dispatch(setStatus(values,
        props.router.navigate))
      resetForm({
        values,
      })
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <Col className="mt-5 pb-5 text-center">
              <h3 className="text-primary fw-semibold display-5 mt-5">
                <Trans i18nKey="badge.playersCount.title"></Trans>
              </h3>
              <h5>
                <Trans i18nKey="badge.playersCount.description"></Trans>
              </h5>
            </Col>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  playersCountValidation.handleSubmit()
                  return false
                }}
              >
                {error ? <Alert color="danger">{error}</Alert> : null}

                <div className="mb-3">
                  <Label className="form-label" for="playersCount">
                    <Trans i18nKey="badge.playersCount.playersCount"></Trans>
                  </Label>
                  <Input
                    name="playersCount"
                    id="playersCount"
                    // value={playersCountValidation.values.playersCount}
                    type="number"
                    className="p-3 border-input"
                    placeholder={i18n.t("badge.playersCount.playersCount")}
                    autoFocus={true}
                    disabled={props.loading}
                    onChange={playersCountValidation.handleChange}
                    onBlur={playersCountValidation.handleBlur}
                    invalid={
                      playersCountValidation.touched.playersCount &&
                      playersCountValidation.errors.playersCount
                        ? true
                        : false
                    }
                  />
                  {playersCountValidation.touched.playersCount &&
                  playersCountValidation.errors.playersCount ? (
                    <FormFeedback type="invalid">
                      {playersCountValidation.errors.playersCount}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mt-3 d-grid">
                  <button
                    className={
                      "btn btn-block btn-rounded btn-lg " +
                      (playersCountValidation.isValid &&
                      playersCountValidation.dirty
                        ? "btn-primary"
                        : "btn-disabled-light")
                    }
                    type="submit"
                    disabled={
                      props.loading ||
                      !(
                        playersCountValidation.isValid &&
                        playersCountValidation.dirty
                      )
                    }
                  >
                    <Trans i18nKey="badge.playersCount.submit"></Trans>
                  </button>
                </div>
              </Form>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

PlayersCount.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  playersCount: PropTypes.number,
  prepaidPrint: PropTypes.bool,
  players: PropTypes.array,
}

const mapStateToProps = state => {
  const { loading, playersCount, prepaidPrint } = state.Badge
  return { loading, playersCount, prepaidPrint }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(PlayersCount))
)
