import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux";
import { map } from "lodash";

import { Helmet, HelmetProvider } from "react-helmet-async";

// notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//store
import { getPlace } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

class Place extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (props.router.params.idPlace > 0) {
      const postData = {
        idPlace: props.router.params.idPlace,
        type: "invoicing",
      };
      props.getPlace(postData, props.router.navigate);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (props.place && props.place.invoicing
                    ? " : " + props.place.invoicing.name
                    : "") +
                  " | GreenPlay Innovation"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {props.place && props.place.invoicing ? (
              <div>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <h5 className="mt-3 mb-3">
                            <Trans i18nKey="place.pricing" />
                          </h5>
                          {props.place.invoicing.packList.indexOf(
                            "standard"
                          ) === -1 &&
                          props.place.invoicing.packList.indexOf(
                            "birthday"
                          ) === -1 ? (
                            ""
                          ) : (
                            <div>
                              <h6 className="mt-3 mb-3">
                                <Trans i18nKey="place.pricingSmall" />
                              </h6>
                              <Row>
                                <Table className="table mb-0 table-hover">
                                  <thead className="table-light">
                                    <tr>
                                      <th>
                                        <Trans i18nKey="place.printouts"></Trans>
                                      </th>
                                      <th>
                                        <Trans i18nKey="place.commissionWithoutTaxes"></Trans>
                                      </th>
                                      <th>
                                        <Trans i18nKey="place.priceWithTaxes"></Trans>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(
                                      Object.keys(
                                        props.place.invoicing.prices.small
                                          .sale
                                      ),
                                      key => {
                                        return (
                                          <tr key={key}>
                                            <th scope="row">
                                              {key !== "next" ? (
                                                key
                                              ) : (
                                                <Trans i18nKey="place.nextPrintouts"></Trans>
                                              )}
                                            </th>
                                            <td>
                                              {props.place.invoicing.prices.small.commission[
                                                key
                                              ].toFixed(2)}
                                              €
                                            </td>
                                            <td>
                                              {props.place.invoicing.prices.small.sale[
                                                key
                                              ].toFixed(2)}
                                              €
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </Row>
                            </div>
                          )}
                          {props.place.invoicing.packList.indexOf(
                            "premium"
                          ) === -1 &&
                          props.place.invoicing.packList.indexOf(
                            "birthday"
                          ) === -1 ? (
                            ""
                          ) : (
                            <div>
                              <h6 className="mt-4 mb-3">
                                <Trans i18nKey="place.pricingLarge" />
                              </h6>
                              <Row>
                                <Table className="table mb-0  table-hover">
                                  <thead className="table-light">
                                    <tr>
                                      <th>
                                        <Trans i18nKey="place.printouts"></Trans>
                                      </th>
                                      <th>
                                        <Trans i18nKey="place.commissionWithoutTaxes"></Trans>
                                      </th>
                                      <th>
                                        <Trans i18nKey="place.priceWithTaxes"></Trans>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {map(
                                      Object.keys(
                                        props.place.invoicing.prices.large
                                          .sale
                                      ),
                                      key => {
                                        return (
                                          <tr key={key}>
                                            <th scope="row">
                                              {key !== "next" ? (
                                                key
                                              ) : (
                                                <Trans i18nKey="place.nextPrintouts"></Trans>
                                              )}
                                            </th>
                                            <td>
                                              {props.place.invoicing.prices.large.commission[
                                                key
                                              ].toFixed(2)}
                                              €
                                            </td>
                                            <td>
                                              {props.place.invoicing.prices.large.sale[
                                                key
                                              ].toFixed(2)}
                                              €
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </Table>
                              </Row>
                            </div>
                          )}
                          <h5 className="mt-5 mb-3">
                            <Trans i18nKey="place.invoicing" />
                          </h5>
                          <Row className="mb-3">
                            <Col>
                              {props.place.invoicing.stripeLink ? <a href={props.place.invoicing.stripeLink}><Trans i18nKey="place.setupMyBillingInfo"></Trans></a> : <a href={props.place.invoicing.portalCustomerLink}><Trans i18nKey="place.checkMyCustomerPortal"></Trans></a> }
                            </Col>
                          </Row>
                          <h5 className="mt-5 mb-3">
                            <Trans i18nKey="place.invoicing" />
                          </h5>
                          <Row className="mb-3">
                            <Col>
                              <Trans i18nKey="place.noInvoicingForNow"></Trans>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, place } = state.Place;
  return { error, loading, place };
};

export default connect(mapStateToProps, {
  getPlace,
})(withRouter(withTranslation()(Place)));
