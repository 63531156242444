export const PREFIX = "web."
export const DOMAIN = "greenplay-innovation.com"

export const ROOT_CLIENT =
  window.location.host === "localhost:3000"
    ? "http://localhost:3000"
    : "https://" + PREFIX + DOMAIN

export const ROOT_BUCKET =
  window.location.host === "localhost:3000"
    ? "http://localhost/sp/glf/bucket"
    : "https://" + PREFIX + DOMAIN

export const ROOT_PROD_BUCKET = "https://" + PREFIX + DOMAIN

export const ROOT_SERVER = window.location.host.includes("localhost:3000")
  ? "http://localhost/sp/glf/server/api"
  : "https://api." + DOMAIN

//BADGE
export const POST_GET_BADGE = ROOT_SERVER + "/badge/get"
export const POST_SET_STATUS = ROOT_SERVER + "/badge/setStatus"
export const POST_RESET = ROOT_SERVER + "/badge/reset"
export const POST_PRINT_SCORES = ROOT_SERVER + "/badge/printScores"
export const POST_ACCESS_ADMIN = ROOT_SERVER + "/badge/accessAdmin"
export const POST_UPLOAD_PHOTO = ROOT_SERVER + "/badge/uploadPhoto"
export const POST_SEND_EMAIL = ROOT_SERVER + "/badge/sendEmail"
export const POST_SEND_SURVEY = ROOT_SERVER + "/badge/sendSurvey"
export const POST_TAKE_CONTROL = ROOT_SERVER + "/badge/takeControl"

//LOGIN
export const POST_LOGIN = ROOT_SERVER + "/login";
export const POST_SEND_OTP = ROOT_SERVER + "/sendOTP";
export const POST_LOGOUT = ROOT_SERVER + "/logout";

//PROFILE
export const GET_PROFILE = ROOT_SERVER + "/profile/get";
export const EDIT_PROFILE = ROOT_SERVER + "/profile/edit";
export const EDIT_LANGUAGE = ROOT_SERVER + "/language/edit";

// PLACE
export const GET_PLACE = ROOT_SERVER + "/place/get";
export const LIST_PLACES = ROOT_SERVER + "/place/list";
export const ADD_ORDER = ROOT_SERVER + "/place/addOrder";
export const CANCEL_ORDER = ROOT_SERVER + "/place/cancelOrder";
export const EDIT_PLACE = ROOT_SERVER + "/place/edit";

// HARDWARE
export const GET_HARDWARE = ROOT_SERVER + "/hardware/get";
export const LIST_HARDWARE = ROOT_SERVER + "/hardware/list";
export const EDIT_HARDWARE = ROOT_SERVER + "/hardware/edit";

// USER
export const GET_USER = ROOT_SERVER + "/user/get";
export const LIST_USERS = ROOT_SERVER + "/user/list";
export const LOGIN_AS = ROOT_SERVER + "/user/loginAs";

// ORDER
export const GET_ORDER = ROOT_SERVER + "/order/get";
export const LIST_ORDERS = ROOT_SERVER + "/order/list";
export const LAST_ORDERS = ROOT_SERVER + "/order/last";
