import React from "react";
import PropTypes from "prop-types";
import { Container, Row } from "reactstrap";
import withRouter from "components/Common/withRouter"

import { Helmet, HelmetProvider } from "react-helmet-async";

import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import i18n from "../../i18n/i18n";
import { withTranslation } from "react-i18next";

const Dashboard = () => {

    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>{i18n.t("sidebar.dashboard") + " | GreenPlay Innovation"}</title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"GreenPlay Innovation"}
              breadcrumbItem={i18n.t("sidebar.dashboard")}
            />
            <Row></Row>
          </Container>
        </div>
      </React.Fragment>
    );
}

Dashboard.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
};

export default withRouter(withTranslation()(Dashboard));
