import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {

  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")));
  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
      {authUser ? (
        <div id="sidebar-menu"> 
          {authUser.user.type === "client" ? (
            <ul className="metismenu list-unstyled" id="side-menu">
             {/*<li className="menu-title">{props.t("sidebar.menu")}</li>

              <li>
                <Link to="/home" className="" alt="/home">
                  <i className="bx bxs-dashboard" />
                  <span>{props.t("sidebar.dashboard")}</span>
                </Link>
          </li>*/}

              {authUser.places &&
              authUser.places.length > 0 ? (
                <li className="menu-title">
                  {props.t("sidebar.places")}
                </li>
              ) : (
                ""
              )}
              {authUser.places &&
              authUser.places.length > 0 ? (
                authUser.places.length > 1 ? (
                  authUser.places.map((place, i) => {
                    return (
                      <li key={"place" + i}>
                        <Link
                          to={"/place/" + place.id}
                          alt={"/place/" + place.id}
                        >
                          <i className="bx bx bxs-store"></i>
                          <span>{place.name}</span>
                        </Link>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <Link
                      to={"/place/" + authUser.places[0].id}
                      alt={"/place/" + authUser.places[0].id}
                    >
                      <i className="bx bx bxs-store"></i>
                      <span>{authUser.places[0].name}</span>
                    </Link>
                  </li>
                )
              ) : (
                ""
              )}
            </ul>
          ) : (
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("sidebar.menu")}</li>

              <li>
                <Link to="/dashboard" className="" alt="/dashboard">
                  <i className="bx bxs-dashboard" />
                  <span>{props.t("sidebar.dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/places" className="" alt="/places">
                  <i className="bx bxs-store" />
                  <span>{props.t("sidebar.places")}</span>
                </Link>
              </li>

              <li>
                <Link to="/hardware" className="" alt="/hardware">
                  <i className="bx bx-printer" />
                  <span>{props.t("sidebar.hardware")}</span>
                </Link>
              </li>
              <li>
              <Link to="/users" className="" alt="/users">
                <i className="bx bx-user" />
                <span>{props.t("sidebar.users")}</span>
              </Link>
            </li>
              <li>
                <Link to="/orders" className="" alt="/orders">
                  <i className="bx bx-receipt" />
                  <span>{props.t("sidebar.orders")}</span>
                </Link>
              </li>

            </ul>
          )}
        </div>
      ) : (
        ""
      )}
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
