export const GET_HARDWARE = "GET_HARDWARE";
export const GET_HARDWARE_SUCCESS = "GET_HARDWARE_SUCCESS";
export const GET_HARDWARE_FAIL = "GET_HARDWARE_FAIL";

export const LIST_HARDWARE = "LIST_HARDWARE";
export const LIST_HARDWARE_SUCCESS = "LIST_HARDWARE_SUCCESS";
export const LIST_HARDWARE_FAIL = "LIST_HARDWARE_FAIL";

export const EDIT_HARDWARE = "EDIT_HARDWARE";
export const EDIT_HARDWARE_SUCCESS = "EDIT_HARDWARE_SUCCESS";
export const EDIT_HARDWARE_FAIL = "EDIT_HARDWARE_FAIL";