import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Container,
  Col,
  Carousel,
  CarouselItem,
  // CarouselControl,
  CarouselIndicators,
  Modal,
} from "reactstrap"

//redux
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

// i18n
import i18n from "../../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

// bucket
import { ROOT_BUCKET } from "../../../helpers/url"

const Rules = props => {
  const [modal_center, setmodal_center] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    goToIndex(0)
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const onExiting = () => {
    setAnimating(true)
  }

  const onExited = () => {
    setAnimating(false)
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
    if (nextIndex > 0) setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
    if (nextIndex < items.length - 1) setActiveIndex(nextIndex)
  }

  const goToIndex = newIndex => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const items =
    props.rules && props.rules[i18n.language] ? props.rules[i18n.language] : []

  const slides = items.map(item => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.src}>
        <img
          className="img-fluid"
          src={
            ROOT_BUCKET +
            "/config/" +
            props.router.params.idPlace +
            "/img/" +
            item.src
          }
          alt={item.caption}
        />
        <div className="carousel-caption mt-4">
          <h3>{item.caption}</h3>
          <p>{item.altText}</p>
        </div>
      </CarouselItem>
    )
  })

  useEffect(() => {
    if (props.show) tog_center()
  }, [])

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={props.show}
          size="xl"
          centered
          backdrop={"static"}
          fullscreen={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <Trans i18nKey="badge.game.rules"></Trans>
            </h5>
            <button
              type="button"
              onClick={() => {
                props.close()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="page-content">
              <Container>
                <div className="vertical-center text-center">
                  <Col className="mb-5 pb-5">
                    <Carousel
                      activeIndex={activeIndex}
                      next={next}
                      previous={previous}
                      dark={true}
                      interval={false}
                    >
                      <CarouselIndicators
                        items={items}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                      />
                      {slides}
                      {/*
                    // <CarouselControl
                      //   direction="prev"
                      //   directionText="Previous"
                      //   onClickHandler={previous}
                      // />
                      // <CarouselControl
                      //   direction="next"
                      //   directionText="Next"
                      //   onClickHandler={next}
                      // />
                  */}
                    </Carousel>
                  </Col>
                </div>
              </Container>
            </div>
          </div>
          <div className="mb-3 footer bg-transparent">
            {activeIndex >= items.length - 1 ? (
              <div className="d-grid">
                <button
                  className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    props.close()
                  }}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <Trans i18nKey="badge.rules.closeRules"></Trans>
                </button>
              </div>
            ) : (
              <div>
                {activeIndex > 0 ? (
                  <button
                    className="btn btn-link btn-nav-rules fw-semibold btn-lg"
                    type="button"
                    onClick={() => {
                      previous()
                    }}
                  >
                    <Trans i18nKey="badge.rules.previous"></Trans>
                  </button>
                ) : (
                  ""
                )}
                <button
                  className="btn btn-link btn-nav-rules float-end fw-semibold btn-lg"
                  type="button"
                  onClick={() => {
                    next()
                  }}
                >
                  <Trans i18nKey="badge.rules.next"></Trans>
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </React.Fragment>
  )
}

Rules.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  rules: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, rules } = state.Badge
  return {
    loading,
    rules,
  }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Rules))
)
