import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import {
  GET_PLACE,
  LIST_PLACES,
  ADD_ORDER,
  CANCEL_ORDER,
  EDIT_PLACE,
} from "./actionTypes";
import {
  getPlaceSuccess,
  getPlaceFail,
  listPlacesSuccess,
  listPlacesFail,
  addOrderSuccess,
  addOrderFail,
  cancelOrderSuccess,
  cancelOrderFail,
  editPlaceSuccess,
  editPlaceFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getPlaceCall,
  listPlacesCall,
  addOrderCall,
  cancelOrderCall,
  editPlaceCall,
} from "../../helpers/backend";

function* getPlace({ payload: { place, history } }) {
  try {
    const response = yield call(getPlaceCall, place);
    if (response.status === "success") {
      yield put(getPlaceSuccess(response));
    } else {
      yield put(getPlaceFail(response));
    }
  } catch (error) {
    yield put(getPlaceFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* listPlaces({ payload: { history } }) {
  try {
    const response = yield call(listPlacesCall);
    if (response.status === "success") {
      yield put(listPlacesSuccess(response));
    } else {
      yield put(listPlacesFail(response));
    }
  } catch (error) {
    yield put(listPlacesFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* addOrder({ payload: { place, history } }) {
  try {
    const response = yield call(addOrderCall, place);
    if (response.status === "success") {
      yield put(addOrderSuccess(response));
    } else {
      yield put(addOrderFail(response));
    }
  } catch (error) {
    yield put(addOrderFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* cancelOrder({ payload: { place, history } }) {
  try {
    const response = yield call(cancelOrderCall, place);
    if (response.status === "success") {
      yield put(cancelOrderSuccess(response));
    } else {
      yield put(cancelOrderFail(response));
    }
  } catch (error) {
    yield put(cancelOrderFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* editPlace({ payload: { place, history } }) {
  try {
    const response = yield call(editPlaceCall, place);
    if (response.status === "success") {
      yield put(editPlaceSuccess(response));
    } else {
      yield put(editPlaceFail(response));
    }
  } catch (error) {
    yield put(editPlaceFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}


export function* watchPlace() {
  yield takeEvery(GET_PLACE, getPlace);
  yield takeEvery(LIST_PLACES, listPlaces);
  yield takeEvery(ADD_ORDER, addOrder);
  yield takeEvery(CANCEL_ORDER, cancelOrder);
  yield takeEvery(EDIT_PLACE, editPlace);
}

function* PlaceSaga() {
  yield all([fork(watchPlace)]);
}

export default PlaceSaga;
