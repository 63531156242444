import {
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_LANGUAGE,
  EDIT_LANGUAGE_SUCCESS,
  EDIT_LANGUAGE_FAIL
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../../i18n/i18n";

export const getProfile = (history) => {
  return {
    type: GET_PROFILE,
    payload: {history} ,
  };
};

export const getProfileSuccess = msg => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const getProfileFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_PROFILE_FAIL,
    payload: fail,
  };
};

export const editProfile = (user, history) => {
  return {
    type: EDIT_PROFILE,
    payload: { user, history },
  };
};

export const editProfileSuccess = msg => {
  toast.success(i18n.t(msg.message));
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: msg,
  };
};

export const editProfileFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: EDIT_PROFILE_FAIL,
    payload: fail,
  };
};


export const editLanguage = (user, history) => {
  return {
    type: EDIT_LANGUAGE,
    payload: { user, history },
  };
};

export const editLanguageSuccess = msg => {
  // toast.success(i18n.t(msg.message));
  return {
    type: EDIT_LANGUAGE_SUCCESS,
    payload: msg,
  };
};

export const editLanguageFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: EDIT_LANGUAGE_FAIL,
    payload: fail,
  };
};