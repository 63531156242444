import PropTypes from "prop-types"
import React from "react"

import { Container, Row, Col } from "reactstrap"

//redux
//redux
import { connect, useDispatch } from "react-redux" //, useDispatch
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

// bucket
import { ROOT_BUCKET } from "../../../helpers/url"

// actions
import { takeControl, reset } from "../../../store/actions"

const ReadOnly = props => {
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <Fade>
        <div className="page-content">
          <Container>
            <div className="vertical-center text-center">
              <Col>
                <img
                  src={
                    ROOT_BUCKET +
                    "/config/" +
                    props.router.params.idPlace +
                    "/img/readOnly.png"
                  }
                  alt=""
                  className="img-fluid"
                />
              </Col>
              <Col className="px-2">
                <h1 className="text-primary fw-semibold display-4 mt-5">
                  <Trans i18nKey="badge.readOnly.title"></Trans>
                </h1>
                <h4>
                  <Trans i18nKey="badge.readOnly.description"></Trans>
                  <br />
                  <br />
                  {props.players && props.players.length > 0 ? (
                    <b>
                      {props.players.map((playerValue, playerIndex) => (
                        <span key={playerIndex}>{playerValue}, </span>
                      ))}
                      <Trans i18nKey="badge.readOnly.team"></Trans>
                      <br />
                      <br />
                    </b>
                  ) : (
                    ""
                  )}

                  <Trans
                    i18nKey="badge.readOnly.yesTeam"
                    components={{
                      strongSuccess: <strong className="text-success" />,
                    }}
                  ></Trans>
                  <br />
                  <Trans
                    i18nKey="badge.readOnly.noTeam"
                    components={{
                      strongDanger: <strong className="text-danger" />,
                    }}
                  ></Trans>
                  <br />
                </h4>
              </Col>
            </div>

            <div className="mb-3 footer bg-transparent">
              <div className="d-grid">
                <Row>
                  <Col size={6}>
                    {" "}
                    <button
                      className="btn btn-rounded btn-danger btn-lg fw-semibold"
                      type="button"
                      disabled={props.loading}
                      onClick={() => {
                        dispatch(
                          reset(
                            {
                              idPlace: props.router.params.idPlace,
                              idBadge: props.router.params.idBadge,
                              token: props.router.params.token,
                              // password: validation.values.password,
                            },
                            props.router.navigate
                          )
                        )
                      }}
                    >
                      <Trans i18nKey="badge.readOnly.reset"></Trans>
                    </button>
                  </Col>
                  <Col size={6}>
                    {" "}
                    <button
                      className="btn btn-rounded btn-success btn-block float-end fw-semibold btn-lg"
                      type="button"
                      disabled={props.loading}
                      onClick={() => {
                        dispatch(
                          takeControl({
                            idPlace: props.router.params.idPlace || "",
                            idBadge: props.router.params.idBadge || 0,
                            token: props.router.params.token || "",
                          })
                        )
                      }}
                    >
                      <Trans i18nKey="badge.readOnly.submit"></Trans>
                    </button>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
        </div>
      </Fade>
    </React.Fragment>
  )
}

ReadOnly.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  players: PropTypes.array,
}

const mapStateToProps = state => {
  const { loading, players } = state.Badge
  return { loading, players }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ReadOnly))
)
