import {
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
  LIST_USERS,
  LIST_USERS_FAIL,
  LIST_USERS_SUCCESS,
  LOGIN_AS,
  LOGIN_AS_FAIL,
  LOGIN_AS_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getUser = (user, history) => {
  return {
    type: GET_USER,
    payload: { user, history },
  };
};

export const getUserSuccess = user => {
  toast.success(i18n.t(user.message));
  return {
    type: GET_USER_SUCCESS,
    payload: user.details,
  };
};

export const getUserFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_USER_FAIL,
    payload: fail,
  };
};

export const listUsers = history => {
  return {
    type: LIST_USERS,
    payload: { history },
  };
};

export const listUsersSuccess = users => {
  // toast.success(i18n.t(users.message));
  return {
    type: LIST_USERS_SUCCESS,
    payload: users.details,
  };
};

export const listUsersFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LIST_USERS_FAIL,
    payload: fail,
  };
};

export const loginAs = (user, history) => {
  return {
    type: LOGIN_AS,
    payload: { user, history },
  };
};

export const loginAsSuccess = user => {
  toast.success(i18n.t(user.message));
  return {
    type: LOGIN_AS_SUCCESS,
    payload: user.details,
  };
};

export const loginAsFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LOGIN_AS_FAIL,
    payload: fail,
  };
};