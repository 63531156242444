import React from "react"
import { Container, Row, Col } from "reactstrap"

// i18n
import { Trans, withTranslation } from "react-i18next"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>
              {" "}
              © {new Date().getFullYear()}{" "}
              <span className="text-primary">GreenPlay Innovation</span>.
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
              <Trans i18nKey="footer.craftedWith" />{" "}
              <i className="mdi mdi-heart text-danger" />{" "}
              <Trans i18nKey="footer.by" />{" "}
              <span className="text-primary">GreenPlay Innovation</span>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default (withTranslation()(Footer))
