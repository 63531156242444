import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../assets/scss/custom/plugins/_datatables.scss"

import { Helmet, HelmetProvider } from "react-helmet-async"

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//store
import { getPlace, editHardware } from "../../store/actions"

//i18n
import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

import { DateTime } from "luxon"

const Place = props => {
  const [sizePerPage] = useState(10)
  const [currentOpenedHardware] = useState({}) // , setCurrentOpenedHardware
  const [modalPinIsOpened, setModalPinIsOpened] = useState(false)

  useEffect(() => {
    if (props.router.params.idPlace > 0) {
      let postData = {
        idPlace: props.router.params.idPlace,
        type: "printstations",
      }
      props.getPlace(postData, props.router.navigate)

      postData = {
        idPlace: props.router.params.idPlace,
        type: "badges",
      }
      props.getPlace(postData, props.router.navigate)
    }
  }, [])

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.editHardwareUpdated !== props.editHardwareUpdated &&
  //     props.editHardwareUpdated === true
  //   ) {
  //     setState({ modalPinIsOpened: false });
  //     const postData = {
  //       idPlace: props.router.params.idPlace,
  //       type: "hardware",
  //     };
  //     props.getPlace(postData, props.router.navigate);
  //   }
  // }

  const togModalPin = () => {
    setModalPinIsOpened(!modalPinIsOpened)
  }

  const handleDate = cell => {
    return cell !== "-" ? DateTime.fromSQL(cell).toFormat("DDDD t") : cell
  }

  const columns = [
    {
      dataField: "idBadge",
      text: i18n.t("place.id"),
      // formatter: handleDate,
      sort: true,
      // filterValue: handleDate,
      searchable: true,
    },
    {
      dataField: "status",
      text: i18n.t("place.status"),
      // formatter: handleDetails,
      sort: true,
      // filterValue: handleFilterDetails,
      searchable: true,
    },
    {
      dataField: "timestamp",
      text: i18n.t("place.lastComm"),
      formatter: handleDate,
      sort: true,
      filterValue: handleDate,
      searchable: true,
    },
    // ,
    // {
    //   dataField: "landingPage",
    //   text: i18n.t("place.actions"),
    //   formatter: handleActions,
    //   sort: false,
    //   searchable: false,
    // },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage,
    totalSize:
      props.place &&
      props.place.badges &&
      props.place.badges.length &&
      props.place.badges[0].idBadge !== null
        ? props.place.badges.length
        : 0, // replace later with size(customers),
    custom: true,
  }

  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <HelmetProvider>
          <Helmet>
            <title>
              {i18n.t("place.title") +
                (props.place && props.place.printstations
                  ? " : " + props.place.printstations[0].name
                  : "") +
                " | GreenPlay Innovation"}
            </title>
          </Helmet>
        </HelmetProvider>
        <Container fluid>
          {props.place && props.place.printstations ? (
            <div>
              <Modal
                isOpen={modalPinIsOpened}
                toggle={togModalPin}
                centered={true}
                backdrop="static"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    pin: currentOpenedHardware.pin || "0000",
                    idComputer: currentOpenedHardware.idComputer || 0,
                  }}
                  validationSchema={Yup.object().shape({
                    pin: Yup.string()
                      .required(i18n.t("place.enterPin"))
                      .matches("^[0-9]{4}$", i18n.t("place.enter4digits")),
                  })}
                  onSubmit={values => {
                    props.editHardware(values, props.router.navigate)
                  }}
                >
                  {({ handleBlur, setFieldValue, errors, touched }) => (
                    <Form className="form-horizontal">
                      <ModalHeader>
                        <span className="text-warning">
                          <Trans i18nKey="place.pinTitle"></Trans>
                        </span>
                        <button
                          type="button"
                          onClick={() => setModalPinIsOpened(false)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={props.loading}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <div className="mt-3 mb-3">
                          <Trans i18nKey="place.pinDescription"></Trans>
                        </div>
                        <div className="text-center">
                          <div className="mb-1">
                            <i
                              className="bx bx bx-printer display-6"
                              style={{
                                lineHeight: "1",
                                color: currentOpenedHardware.dashboardColor,
                              }}
                            />
                          </div>
                          <p>
                            <Trans i18nKey="place.id"></Trans> :{" "}
                            {currentOpenedHardware.idComputer}
                            <br />
                            <Trans i18nKey="place.orders"></Trans> :{" "}
                            {currentOpenedHardware.orders}
                          </p>
                        </div>
                        <div className="mb-3">
                          <Label for="pin" className="form-label">
                            <Trans i18nKey={"place.yourPin"} /> *
                          </Label>
                          <Field
                            name="pin"
                            label={i18n.t("place.yourPin")}
                            type="string"
                            disabled={props.loading}
                            className={
                              "form-control" +
                              (errors.pin && touched.pin ? " is-invalid" : "")
                            }
                            onChange={e => {
                              const value = e.target.value || ""
                              setFieldValue("pin", value)
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="pin"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Alert color={"danger"}>
                          <i className="fas fa-info-circle"></i>{" "}
                          <Trans i18nKey="place.pinDisclaimer"></Trans>
                        </Alert>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togModalPin}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.cancel"></Trans>
                        </button>
                        <button
                          className="btn btn-warning"
                          type="submit"
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.update"></Trans>
                        </button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <h5 className="mt-3 mb-5">
                          <Trans i18nKey="place.printStations" />
                        </h5>
                        <Row>
                          {props.place &&
                          props.place.printstations &&
                          props.place.printstations.length > 0 &&
                          props.place.printstations[0].idComputer !== null ? (
                            props.place.printstations.map(prop => {
                              return (
                                <Col
                                  key={prop.idComputer}
                                  xl={4}
                                  l={6}
                                  className="mb-2"
                                >
                                  <div className="text-center">
                                    <div className="mb-1">
                                      <i
                                        className="bx bx bx-printer display-6"
                                        style={{
                                          lineHeight: "1",
                                          color: prop.dashboardColor,
                                        }}
                                      />
                                    </div>
                                    <p>
                                      <Trans i18nKey="place.id"></Trans> :{" "}
                                      {prop.idComputer}
                                      <br />
                                      <Trans i18nKey="place.orders"></Trans> :{" "}
                                      {prop.orders}
                                      <br />
                                      {/* <Link
                                        to={"/upload/" + prop.idComputer}
                                        target="_blank"
                                        className="btn btn-primary position-relative p-0 avatar-xs rounded-circle"
                                        style={{
                                          backgroundColor: prop.dashboardColor,
                                          borderColor: prop.dashboardColor,
                                        }}
                                        title={i18n.t("place.uploader")}
                                      >
                                        <span className="avatar-title bg-transparent text-reset">
                                          <i className="bx bx-cloud-upload"></i>
                                        </span>
                                      </Link>{" "}
                                      <button
                                        target="_blank"
                                        className="btn btn-warning position-relative p-0 avatar-xs rounded-circle"
                                        title={i18n.t("place.pin")}
                                        onClick={e => {
                                          setCurrentOpenedHardware(prop)
                                          togModalPin(e)
                                        }}
                                      >
                                        <span className="avatar-title bg-transparent text-reset">
                                          <i className="bx bx-key"></i>
                                        </span>
                                      </button>
                                      */}
                                    </p>
                                  </div>
                                </Col>
                              )
                            })
                          ) : (
                            <p>
                              <Trans i18nKey="hardware.noComputers"></Trans>
                            </p>
                          )}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <h5 className="mt-3 mb-5">
                          <Trans i18nKey="place.badges" />
                        </h5>
                        <Row>
                          {props.place &&
                            props.place.badges && props.place.badges.length > 0 ? (
                            <PaginationProvider
                              pagination={paginationFactory(pageOptions)}
                              keyField="idBadge"
                              columns={columns}
                              data={
                                props.place.badges &&
                                props.place.badges[0].idBadge !== null
                                  ? props.place.badges
                                  : []
                              }
                            >
                              {({ paginationProps, paginationTableProps }) => (
                                <ToolkitProvider
                                  keyField="idBadge"
                                  columns={columns}
                                  data={
                                    props.place.badges &&
                                    props.place.badges[0].idBadge !== null
                                      ? props.place.badges
                                      : []
                                  }
                                  search
                                  bootstrap4
                                >
                                  {toolkitProps => (
                                    <React.Fragment>
                                      <Row className="mb-2">
                                        <Col md="4">
                                          <div className="search-box me-2 mb-2 d-inline-block">
                                            <div className="position-relative">
                                              <SearchBar
                                                {...toolkitProps.searchProps}
                                                placeholder={i18n.t(
                                                  "place.search"
                                                )}
                                              />
                                              <i className="bx bx-search-alt search-icon" />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col xl="12">
                                          <div className="table-responsive">
                                            <BootstrapTable
                                              keyField={"idBadge"}
                                              responsive
                                              bordered={false}
                                              striped={false}
                                              hover={true}
                                              defaultSorted={defaultSorted}
                                              // selectRow={selectRow}
                                              classes={
                                                "table align-middle table-nowrap table-check"
                                              }
                                              headerWrapperClasses={
                                                "table-light"
                                              }
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                              noDataIndication={
                                                <Trans i18nKey="place.emptyListBadges"></Trans>
                                              }
                                            />
                                          </div>
                                        </Col>
                                      </Row>

                                      <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                          {
                                            // <div className="d-inline">
                                            //   <SizePerPageDropdownStandalone
                                            //     {...paginationProps}
                                            //   />
                                            // </div>
                                          }
                                          <div className="text-md-right ms-auto">
                                            <div className="pagination pagination-rounded justify-content-end">
                                              <PaginationListStandalone
                                                {...paginationProps}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </React.Fragment>
                                  )}
                                </ToolkitProvider>
                              )}
                            </PaginationProvider>
                          ) : (
                            <p>
                              <Trans i18nKey="hardware.noBadges"></Trans>
                            </p>
                          )}
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Place.propTypes = {
  t: PropTypes.any,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  editHardwareUpdated: PropTypes.bool,
  getPlace: PropTypes.func,
  editHardware: PropTypes.func,
}

const mapStateToProps = state => {
  const { error, loading, place, editHardwareUpdated } = state.Place
  return { error, loading, place, editHardwareUpdated }
}

export default connect(mapStateToProps, {
  getPlace,
  editHardware,
})(withRouter(withTranslation()(Place)))
