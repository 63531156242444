import usFlag from "../assets/images/flags/us.jpg";
// import spain from "../assets/images/flags/spain.jpg";
// import germany from "../assets/images/flags/germany.jpg";
// import italy from "../assets/images/flags/italy.jpg";
import france from "../assets/images/flags/french.jpg";
// import russia from "../assets/images/flags/russia.jpg";

const languages = {
  // es: {
  //   label: "Español",
  //   flag: spain,
  // },
  // de: {
  //   label: "Deutsch",
  //   flag: germany,
  // },
  // ru: {
  //   label: "Pусский",
  //   flag: russia,
  // },
  // it: {
  //   label: "Italiano",
  //   flag: italy,
  // },
  fr: {
    label: "Français",
    flag: france,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages;
