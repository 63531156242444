import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

import { ROOT_PROD_BUCKET } from "../../helpers/url"

import { Helmet, HelmetProvider } from "react-helmet-async"

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

//store
import { getPlace, addOrder, cancelOrder } from "../../store/actions"

//i18n
import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../assets/scss/custom/plugins/_datatables.scss"

import { DateTime } from "luxon"

// import { pricing } from "../Utility/Pricing"

const Place = props => {
  // const [page, setPage] = useState(1)
  const [sizePerPage] = useState(10)
  const [currentOpenedPhoto, setCurrentOpenedPhoto] = useState("")
  const [isOpenedPhoto, setIsOpenedPhoto] = useState(false)
  const [currentOpenedOrder] = useState({})
  const [modalCancelIsOpened, setModalCancelIsOpened] = useState(false)
  const [modalAddIsOpened, setModalAddIsOpened] = useState(false)

  // this.togModalCancel = this.togModalCancel.bind(this);
  // this.togModalAdd = this.togModalAdd.bind(this);
  // this.handleDetails = this.handleDetails.bind(this);
  // this.handleActions = this.handleActions.bind(this);
  const attachment1Ref = useRef();

  useEffect(() => {
    if (props.router.params.idPlace > 0) {
      const postData = {
        idPlace: props.router.params.idPlace,
        type: "orders",
      }
      props.getPlace(postData, props.router.navigate)
    }
  }, [])

  // componentDidUpdate(prevProps) {
  //   if (
  //     prevProps.endorsementAdded !== props.endorsementAdded &&
  //     props.endorsementAdded === true
  //   ) {
  //     this.setState({ modalAddIsOpened: false });
  //     if (props.router.params.idPlace > 0) {
  //       const postData = {
  //         idPlace: props.router.params.idPlace,
  //         type: "orders",
  //       };
  //       props.getPlace(postData, props.router.navigate);
  //     }
  //   } else if (
  //     prevProps.orderCancelled !== props.orderCancelled &&
  //     props.orderCancelled === true
  //   ) {
  //     this.setState({ modalCancelIsOpened: false });
  //     if (props.router.params.idPlace > 0) {
  //       const postData = {
  //         idPlace: props.router.params.idPlace,
  //         type: "orders",
  //       };
  //       props.getPlace(postData, props.router.navigate);
  //     }
  //   }
  // }

  const togModalAdd = () => {
    setModalAddIsOpened(!modalAddIsOpened)
  }

  const togModalCancel = () => {
    setModalCancelIsOpened(!modalCancelIsOpened)
  }

  const handleDate = cell => {
    return cell !== "-" ? DateTime.fromSQL(cell).toFormat("DDDD t") : cell
  }

  const handleDetails = (cell, row) => {
    return (
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <img
            onClick={() => {
              setCurrentOpenedPhoto(
                ROOT_PROD_BUCKET +
                  "/order/" +
                  row.subfolder +
                  props.router.params.idPlace +
                  "/" +
                  row.filename +
                  " - " +
                  row.idBadge + 
                  ".jpg"
              )
              setIsOpenedPhoto(true)
            }}
            className="rounded me-2 img-thumbnail"
            width="200px"
            src={
              ROOT_PROD_BUCKET +
              "/order/" +
              row.subfolder +
              props.router.params.idPlace +
              "/" +
              row.filename +
              " - " +
              row.idBadge + 
              ".jpg"
            }
          ></img>
        </div>
        <div className="flex-grow-1">
          <h5>
            {row.idOrderParent > 0 ? (
              <span className="text-secondary">
                <Trans i18nKey="place.endorsement"></Trans>
                <br />
                {i18n.t("place.price")} : {row.price}€
              </span>
            ) : (
              <span className="text-primary">
                <Trans i18nKey="place.order"></Trans>
                <br />
                {i18n.t("place.price")} : {row.price}€
              </span>
            )}
          </h5>
          <p className="mb-0">
            <b
              className={
                row.status == "cancelled"
                  ? "text-danger"
                  : row.status == "AskedForCancel"
                  ? "text-warning"
                  : row.status == "printed"
                  ? "text-success"
                  : "text-info"
              }
            >
              {i18n.t("place.orderStatus")} : {i18n.t("place." + row.status)}
            </b>
            <br />
            {i18n.t("place.printStation")} : {cell}{" "}
            {row.computerStillAvailable > 0
              ? ""
              : i18n.t("place.printStationNotAvailable")}
            <br />
            { /*i18n.t("place.pack")} : {i18n.t("upload." + row.pack + "Pack") <br /> */}
            
            {i18n.t("place.quantity")} : {row.quantity}{" "}
            {row.endorsementQuantity > 0 ? (
              <span>
                <br />
                {i18n.t("place.quantityViaEndorsement")} :{" "}
                {row.endorsementQuantity}{" "}
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    )
  }

  const handleFilterDetails = (cell, row) => {
    return (
      cell +
      " " +
      i18n.t("upload." + row.pack + "Pack") +
      " " +
      row.quantity +
      " " +
      row.price
    )
  }

  // const handleActions = (cell, row) => {
  //   const actions = false
  //   return (
  //     <div>
  //       {row.status !== "cancelled" &&
  //       row.status !== "AskedForCancel" &&
  //       row.computerStillAvailable > 0 ? (
  //         <div>
  //           <Link
  //             to={cell}
  //             target="_blank"
  //             className="btn btn-primary position-relative p-0 avatar-xs rounded-circle"
  //             title={i18n.t("place.openLandingPage")}
  //           >
  //             <span className="avatar-title bg-transparent text-reset">
  //               <i className="bx bx-link"></i>
  //             </span>
  //           </Link>{" "}
  //           {row.idOrderParent > 0 || !actions ? (
  //             ""
  //           ) : (
  //             <button
  //               onClick={e => {
  //                 setCurrentOpenedOrder(row)
  //                 togModalAdd(e)
  //               }}
  //               target="_blank"
  //               className="btn btn-success position-relative p-0 avatar-xs rounded-circle"
  //               title={i18n.t("place.addToOrder")}
  //             >
  //               <span className="avatar-title bg-transparent text-reset">
  //                 <i className="bx bx-plus"></i>
  //               </span>
  //             </button>
  //           )}{" "}
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </div>
  //   )
  // }

  const columns = [
    {
      dataField: "createdAt",
      text: i18n.t("place.dateTime"),
      formatter: handleDate,
      sort: true,
      filterValue: handleDate,
      searchable: true,
    },
    {
      dataField: "idComputer",
      text: i18n.t("place.details"),
      formatter: handleDetails,
      sort: true,
      filterValue: handleFilterDetails,
      searchable: true,
    }
    // ,
    // {
    //   dataField: "landingPage",
    //   text: i18n.t("place.actions"),
    //   formatter: handleActions,
    //   sort: false,
    //   searchable: false,
    // },
  ]

  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage,
    totalSize:
      props.place &&
      props.place.orders &&
      props.place.orders.length &&
      props.place.orders[0].idOrder !== null
        ? props.place.orders.length
        : 0, // replace later with size(customers),
    custom: true,
  }

  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        <HelmetProvider>
          <Helmet>
            <title>
              {i18n.t("place.title") +
                (props.place && props.place.orders
                  ? " : " + props.place.orders[0].name
                  : "") +
                " | GreenPlay Innovation"}
            </title>
          </Helmet>
        </HelmetProvider>
        <Container fluid>
          {props.place && props.place.orders ? (
            <div>
              <Modal
                isOpen={modalAddIsOpened}
                toggle={togModalAdd}
                centered={true}
                backdrop="static"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    quantity: 0,
                    idOrder: currentOpenedOrder.idOrder || 0,
                  }}
                  validationSchema={Yup.object().shape({
                    quantity: Yup.number()
                      .required(i18n.t("upload.enterQuantity"))
                      .min(1, i18n.t("upload.enterMinQuantity")),
                  })}
                  onSubmit={values => {
                    props.addOrder(values, props.router.navigate)
                  }}
                >
                  {({ handleBlur, setFieldValue, values, errors, touched }) => (
                    <Form className="form-horizontal">
                      <ModalHeader>
                        <span className="text-success">
                          <Trans i18nKey="place.addTitle"></Trans>
                        </span>
                        <button
                          type="button"
                          onClick={() => setModalAddIsOpened(false)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={props.loading}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <div className="mt-3 mb-3">
                          <Trans i18nKey="place.addDescription"></Trans>
                          <div className="mt-3 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <img
                                onClick={() => {
                                  setCurrentOpenedPhoto(
                                    ROOT_PROD_BUCKET +
                                      "/order/" +
                                      currentOpenedOrder.subfolder +
                                      props.router.params.idPlace +
                                      "/" +
                                      currentOpenedOrder.filename +
                                      " - " +
                                      currentOpenedOrder.idBadge + 
                                      ".jpg"
                                  )
                                  setIsOpenedPhoto(true)
                                }}
                                className="rounded me-2 img-thumbnail"
                                width="200px"
                                src={
                                  ROOT_PROD_BUCKET +
                                  "/order/" +
                                  currentOpenedOrder.subfolder +
                                  props.router.params.idPlace +
                                  "/" +
                                  currentOpenedOrder.filename +
                                  " - " +
                                  currentOpenedOrder.idBadge + 
                                  ".jpg"
                                }
                              ></img>
                            </div>
                            <div className="flex-grow-1">
                              <h5>
                                {currentOpenedOrder.idOrderParent > 0 ? (
                                  <span className="text-secondary">
                                    <Trans i18nKey="place.endorsement"></Trans>
                                    <br />
                                    {i18n.t("place.price")} :{" "}
                                    {currentOpenedOrder.price}€
                                  </span>
                                ) : (
                                  <span className="text-primary">
                                    <Trans i18nKey="place.order"></Trans>
                                    <br />
                                    {i18n.t("place.price")} :{" "}
                                    {currentOpenedOrder.price}€
                                  </span>
                                )}
                              </h5>
                              <p className="mb-0">
                                <b
                                  className={
                                    currentOpenedOrder.status == "cancelled"
                                      ? "text-danger"
                                      : currentOpenedOrder.status ==
                                        "AskedForCancel"
                                      ? "text-warning"
                                      : currentOpenedOrder.status == "printed"
                                      ? "text-success"
                                      : "text-info"
                                  }
                                >
                                  {i18n.t("place.orderStatus")} :{" "}
                                  {i18n.t("place." + currentOpenedOrder.status)}
                                </b>
                                <br />
                                {i18n.t("place.printStation")} :{" "}
                                {currentOpenedOrder.idComputer}
                                <br />
                                {i18n.t("place.pack")} :{" "}
                                {i18n.t(
                                  "upload." + currentOpenedOrder.pack + "Pack"
                                )}
                                <br />
                                {i18n.t("place.quantity")} :{" "}
                                {currentOpenedOrder.quantity}{" "}
                                {currentOpenedOrder.endorsementQuantity > 0 ? (
                                  <span>
                                    <br />
                                    {i18n.t(
                                      "place.quantityViaEndorsement"
                                    )} :{" "}
                                    {currentOpenedOrder.endorsementQuantity}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label for="quantity" className="form-label">
                            <Trans i18nKey={"place.quantityToAdd"} /> *
                          </Label>
                          <Field
                            name="quantity"
                            label={i18n.t("place.yourQuantity")}
                            type="number"
                            disabled={props.loading}
                            className={
                              "form-control" +
                              (errors.quantity && touched.quantity
                                ? " is-invalid"
                                : "")
                            }
                            onChange={e => {
                              const value = e.target.value || ""
                              setFieldValue("quantity", value)
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="quantity"
                            component="div"
                            className="invalid-feedback"
                          />
                          {values.quantity > 0 ? (
                            <Row className="mt-4">
                              <Col lg={12}>
                                <Card
                                  outline
                                  color="primary"
                                  className="border mb-0"
                                >
                                  <CardBody className="p-4">
                                    <div className="text-center">
                                      <div className="avatar-md mx-auto mb-4">
                                        <div className="avatar-title bg-primary rounded-circle text-light h1">
                                          <i className="mdi mdi-information-outline"></i>
                                        </div>
                                      </div>
                                      <Row className="justify-content-center">
                                        <Col xl={10}>
                                          <h4 className="text-primary">
                                            {pricing(
                                              currentOpenedOrder.pack,
                                              parseInt(values.quantity) +
                                                parseInt(
                                                  currentOpenedOrder.quantity
                                                ) +
                                                parseInt(
                                                  currentOpenedOrder.endorsementQuantity
                                                ),
                                              currentOpenedOrder.pricing
                                            ) -
                                              pricing(
                                                currentOpenedOrder.pack,
                                                parseInt(
                                                  currentOpenedOrder.quantity
                                                ) +
                                                  parseInt(
                                                    currentOpenedOrder.endorsementQuantity
                                                  ),
                                                currentOpenedOrder.pricing
                                              )}
                                            €
                                          </h4>
                                          <p className="text-muted font-size-14">
                                            <Trans
                                              i18nKey={
                                                "place." +
                                                currentOpenedOrder.pack +
                                                "PackDescription"
                                              }
                                            ></Trans>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          ) : (
                            ""
                          )}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Alert color={"info"}>
                          <i className="fas fa-info-circle"></i>{" "}
                          <Trans i18nKey="place.addDisclaimer"></Trans>
                        </Alert>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togModalAdd}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.cancel"></Trans>
                        </button>
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.confirm"></Trans>
                        </button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
              <Modal
                isOpen={modalCancelIsOpened}
                toggle={togModalCancel}
                centered={true}
                backdrop="static"
              >
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    reason: "",
                    attachment1: null,
                    idOrder: currentOpenedOrder.idOrder || 0,
                  }}
                  validationSchema={Yup.object().shape({
                    reason: Yup.string().required(i18n.t("place.enterReason")),
                  })}
                  onSubmit={values => {
                    props.cancelOrder(values, props.router.navigate)
                  }}
                >
                  {({ handleBlur, setFieldValue, values, errors, touched }) => (
                    <Form className="form-horizontal">
                      <ModalHeader>
                        <span className="text-danger">
                          <Trans i18nKey="place.areYouSureToCancelTitle"></Trans>
                        </span>
                        <button
                          type="button"
                          onClick={() => setModalCancelIsOpened(false)}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          disabled={props.loading}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <div>
                          <Trans i18nKey="place.areYouSureToCancelDescription"></Trans>
                          <div className="mt-3 d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                              <img
                                onClick={() => {
                                  setCurrentOpenedPhoto(
                                    ROOT_PROD_BUCKET +
                                      "/order/" +
                                      currentOpenedOrder.subfolder +
                                      props.router.params.idPlace +
                                      "/" +
                                      currentOpenedOrder.filename +
                                      " - " +
                                      currentOpenedOrder.idBadge + 
                                      ".jpg"
                                  )
                                  setIsOpenedPhoto(true)
                                }}
                                className="rounded me-2 img-thumbnail"
                                width="200px"
                                src={
                                  ROOT_PROD_BUCKET +
                                  "/order/" +
                                  currentOpenedOrder.subfolder +
                                  props.router.params.idPlace +
                                  "/" +
                                  currentOpenedOrder.filename +
                                  " - " +
                                  currentOpenedOrder.idBadge + 
                                  ".jpg"
                                }
                              ></img>
                            </div>
                            <div className="flex-grow-1">
                              <h5>
                                {currentOpenedOrder.idOrderParent > 0 ? (
                                  <span className="text-secondary">
                                    <Trans i18nKey="place.endorsement"></Trans>
                                    <br />
                                    {i18n.t("place.price")} :{" "}
                                    {currentOpenedOrder.price}€
                                  </span>
                                ) : (
                                  <span className="text-primary">
                                    <Trans i18nKey="place.order"></Trans>
                                    <br />
                                    {i18n.t("place.price")} :{" "}
                                    {currentOpenedOrder.price}€
                                  </span>
                                )}
                              </h5>
                              <p className="mb-0">
                                <b
                                  className={
                                    currentOpenedOrder.status == "cancelled"
                                      ? "text-danger"
                                      : currentOpenedOrder.status ==
                                        "AskedForCancel"
                                      ? "text-warning"
                                      : currentOpenedOrder.status == "printed"
                                      ? "text-success"
                                      : "text-info"
                                  }
                                >
                                  {i18n.t("place.orderStatus")} :{" "}
                                  {i18n.t("place." + currentOpenedOrder.status)}
                                </b>
                                <br />
                                {i18n.t("place.printStation")} :{" "}
                                {currentOpenedOrder.idComputer}
                                <br />
                                {i18n.t("place.pack")} :{" "}
                                {i18n.t(
                                  "upload." + currentOpenedOrder.pack + "Pack"
                                )}
                                <br />
                                {i18n.t("place.quantity")} :{" "}
                                {currentOpenedOrder.quantity}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <Label for="reason" className="form-label">
                            <Trans i18nKey="place.reason" /> *
                          </Label>
                          <Field
                            name="reason"
                            placeholder={i18n.t("place.yourReason")}
                            as="textarea"
                            rows="3"
                            disabled={props.loading}
                            className={
                              "form-control" +
                              (errors.reason && touched.reason
                                ? " is-invalid"
                                : "")
                            }
                            onChange={e => {
                              const value = e.target.value || ""
                              setFieldValue("reason", value)
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="reason"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label for="attachment1" className="form-label">
                            <Trans i18nKey="place.attachment1" />
                          </Label>
                          <div className="input-group">
                            <input
                              ref={attachment1Ref}
                              name="attachment1"
                              type="file"
                              disabled={props.loading}
                              // accept={PHOTO_FILE_TYPE.join(",")}
                              className={
                                "form-control" +
                                (errors.attachment1 && touched.attachment1
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={e => {
                                const value = e.target.files[0] || null
                                setFieldValue("attachment1", value)
                              }}
                              onBlur={handleBlur}
                            />
                            {values.attachment1 !== null ? (
                              <Button
                                color="danger"
                                type="button"
                                onClick={() => {
                                  attachment1Ref.current.value = null
                                  setFieldValue("attachment1", null)
                                }}
                                disabled={props.loading}
                              >
                                <i className="fas fa-times"></i>
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                          <ErrorMessage
                            name="attachment1"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Alert color={"danger"}>
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          <Trans i18nKey="place.areYouSureToCancelDisclaimer"></Trans>
                        </Alert>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togModalCancel}
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.cancel"></Trans>
                        </button>
                        <button
                          className="btn btn-danger"
                          type="submit"
                          disabled={props.loading}
                        >
                          <Trans i18nKey="place.confirm"></Trans>
                        </button>
                      </ModalFooter>
                    </Form>
                  )}
                </Formik>
              </Modal>
              {isOpenedPhoto && (
                <Lightbox
                  mainSrc={currentOpenedPhoto}
                  // nextSrc={images[(photoIndex + 1) % images.length]}
                  // prevSrc={
                  //   images[(photoIndex + images.length - 1) % images.length]
                  // }
                  enableZoom={false}
                  onCloseRequest={() => {
                    setIsOpenedPhoto(false)
                    setCurrentOpenedPhoto("")
                  }}
                />
              )}
              <Row className="justify-content-center">
                <Col lg="12">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2 mb-5">
                        <h5 className="mt-3 mb-3">
                          <Trans i18nKey="place.orders" />
                        </h5>

                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="idOrder"
                          columns={columns}
                          data={
                            props.place.orders &&
                            props.place.orders[0].idOrder !== null
                              ? props.place.orders
                              : []
                          }
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="idOrder"
                              columns={columns}
                              data={
                                props.place.orders &&
                                props.place.orders[0].idOrder !== null
                                  ? props.place.orders
                                  : []
                              }
                              search
                              bootstrap4
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            placeholder={i18n.t("place.search")}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"idOrder"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          hover={true}
                                          defaultSorted={defaultSorted}
                                          // selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={
                                            <Trans i18nKey="place.emptyListOrders"></Trans>
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      {
                                        // <div className="d-inline">
                                        //   <SizePerPageDropdownStandalone
                                        //     {...paginationProps}
                                        //   />
                                        // </div>
                                      }
                                      <div className="text-md-right ms-auto">
                                        <div className="pagination pagination-rounded justify-content-end">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                        <br />
                        <br />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Place.propTypes = {
  t: PropTypes.any,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
  addOrder: PropTypes.func,
  cancelOrder: PropTypes.func,
  endorsementAdded: PropTypes.bool,
  orderCancelled: PropTypes.bool,
}

const mapStateToProps = state => {
  const { error, loading, place, endorsementAdded, orderCancelled } =
    state.Place
  return { error, loading, place, endorsementAdded, orderCancelled }
}

export default connect(mapStateToProps, {
  getPlace,
  addOrder,
  cancelOrder,
})(withRouter(withTranslation()(Place)))
