import PropTypes from "prop-types"
import React from "react"

import { Form, FormFeedback, Alert, Label, Progress } from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// actions
import { uploadPhoto } from "../../../store/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// datetime
import { DateTime } from "luxon"

// file type
import {
  // PHOTO_FILE_SIZE,
  PHOTO_FILE_TYPE,
} from "../../../helpers/files"

const TakePhoto = props => {
  
  const dispatch = useDispatch()

  const takePhotoValidation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
      status: "preview",
      photo: null,
      i18n: localStorage.getItem("i18nextLng") || "fr",
      game: JSON.stringify(props.game) || "",
      scores: JSON.stringify(props.scores) || "",
      date: DateTime.now().toLocaleString(DateTime.DATE_SHORT),
      pt: i18n.t("badge.scores.pt"),
      pts: i18n.t("badge.scores.pts")
    },
    validationSchema: Yup.object({
      // photo: Yup.mixed().required(i18n.t("badge.takePhoto.choosePhoto")),
      // .test("fileFormat", i18n.t("badge.takePhoto.JPEGOnly"), value => {
      //   console.log(value)
      //   return value && PHOTO_FILE_TYPE.includes(value.type)
      // })
      // .test("fileSize", i18n.t("badge.takePhoto.photoTooLarge"), value => {
      //   console.log(value)
      //   return value && value.size <= PHOTO_FILE_SIZE
      // }),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(uploadPhoto(values))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  return (
    <React.Fragment>
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          takePhotoValidation.handleSubmit()
          return false
        }}
      >
        {error ? <Alert color="danger">{error}</Alert> : null}
        <div className="d-grid">
          <Label
            for="photo"
            className={
              "btn btn-rounded btn-primary btn-block btn-lg fw-semibold " +
              (props.loading ? " disabled d-none" : "")
            }
            disabled={props.loading}
          >
            <i className="fa fa-solid fa-camera"></i>{" "}
            <Trans i18nKey="badge.scores.takePhoto" />
          </Label>
          <input
            name="photo"
            id="photo"
            type="file"
            disabled={props.loading}
            accept={PHOTO_FILE_TYPE.join(",")}
            capture="camera"
            onChange={e => {
              const value = e.target.files[0] || null

              if (value !== null && PHOTO_FILE_TYPE.includes(value.type)) {
                takePhotoValidation.setFieldValue("photo", value)
                takePhotoValidation.handleSubmit()
              } else {
                takePhotoValidation.setFieldValue("photo", value)
              }
            }}
            onBlur={takePhotoValidation.handleBlur}
            className={
              "form-control" +
              (takePhotoValidation.touched.photo &&
              takePhotoValidation.errors.photo
                ? " is-invalid"
                : "")
            }
          />
          {takePhotoValidation.touched.photo &&
          takePhotoValidation.errors.photo ? (
            <FormFeedback type="invalid">
              {takePhotoValidation.errors.photo}
            </FormFeedback>
          ) : null}

          {props.progress > 0 ? (
            <div className="mb-3">
              <Progress
                value={props.progress}
                color="primary"
                style={{
                  height: "3rem",
                  borderRadius: "2.5rem",
                  // width: props.progress + "%",
                }}
                animated
              ></Progress>
            </div>
          ) : (
            ""
          )}
        </div>
      </Form>
    </React.Fragment>
  )
}

TakePhoto.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
  progress: PropTypes.number,
  game : PropTypes.object,
  scores : PropTypes.array

}

const mapStateToProps = state => {
  const { loading, status, progress, game, scores } = state.Badge
  return { loading, status, progress, game, scores }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(TakePhoto))
)
