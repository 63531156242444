import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import {
  Container,
  Col,
  Modal,
  Form,
  Input,
  FormFeedback,
  Label,
  Alert,
} from "reactstrap"

//redux
import { connect, useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { accessAdmin, reset, printScores } from "../../../store/actions"

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// bucket
import { ROOT_PROD_BUCKET } from "../../../helpers/url"

// i18n
import { Trans, withTranslation } from "react-i18next"
import i18n from "../../../i18n/i18n"

// img
import nofile from "../../../assets/images/nofile.png"

const AdminAccess = props => {
  const [modal_center, setmodal_center] = useState(false)
  const [emptyFile] = useState(false)

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      password: localStorage.getItem("code") || "",
      idPlace: props.router.params.idPlace || "",
      idBadge: props.router.params.idBadge || 0,
      token: props.router.params.token || "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(i18n.t("badge.activate.enterPassword")),
      idPlace: Yup.number(),
      idBadge: Yup.string(),
      token: Yup.string(),
    }),
    onSubmit: values => {
      dispatch(accessAdmin(values))
    },
  })

  const { error } = useSelector(state => ({
    error: state.Badge.error,
  }))

  useEffect(() => {
    if (props.show) tog_center()
  }, [])

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={props.show}
          size="xl"
          centered
          backdrop={"static"}
          fullscreen={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              <Trans i18nKey="badge.adminAccess.title"></Trans>
            </h5>
            <button
              type="button"
              disabled={props.loading}
              onClick={() => {
                dispatch(
                  accessAdmin({
                    idPlace: props.router.params.idPlace,
                    idBadge: props.router.params.idBadge,
                    token: props.router.params.token,
                    password: "",
                  })
                )
                props.close()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Container fluid>
              <div className="text-center">
                <Col className="">
                  {!props.adminAccess ? (
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label" for="password">
                          <Trans i18nKey="badge.activate.password"></Trans>
                        </Label>
                        <Input
                          name="password"
                          id="password"
                          value={validation.values.password || ""}
                          type="password"
                          className="p-3 border-input"
                          placeholder={i18n.t("badge.activate.password")}
                          autoFocus={true}
                          autoComplete="on"
                          disabled={props.loading}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className={
                            "btn btn-block btn-rounded btn-lg btn-primary"
                          }
                          type="submit"
                          disabled={props.loading}
                        >
                          <Trans i18nKey="badge.adminAccess.submit"></Trans>
                        </button>
                      </div>
                    </Form>
                  ) : (
                    <div className="d-grid">
                      <button
                        className="btn btn-rounded btn-warning btn-block btn-lg fw-semibold"
                        type="button"
                        disabled={
                          props.loading ||
                          props.status === "disabled" ||
                          props.status === "thanks"
                        }
                        onClick={() => {
                          dispatch(
                            reset(
                              {
                                idPlace: props.router.params.idPlace,
                                idBadge: props.router.params.idBadge,
                                token: props.router.params.token,
                                password: validation.values.password,
                              },
                              props.router.navigate
                            )
                          )
                          props.close()
                        }}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <Trans i18nKey="badge.adminAccess.reset"></Trans>
                      </button>
                      <br />
                      <br />
                      <br />
                      {props.previousGames.map((photo, key) => (
                        <div className="d-grid" key={key + "game"}>
                          <img
                            src={
                              ROOT_PROD_BUCKET +
                              "/" +
                              photo +
                              "?timestamp=" +
                              Date.now()
                            }
                            className="img-fluid img-thumbnail img-preview"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null // prevents looping
                              currentTarget.src = nofile
                              // setEmptyFile(true)
                            }}
                          />
                          <button
                            className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold mt-2"
                            type="button"
                            disabled={props.loading || emptyFile}
                            onClick={() => {
                              dispatch(
                                printScores({
                                  idPlace: props.router.params.idPlace,
                                  idBadge: props.router.params.idBadge,
                                  photo,
                                  password: validation.values.password,
                                })
                              )
                            }}
                          >
                            <Trans i18nKey="badge.adminAccess.print"></Trans>
                          </button>
                          <br />
                          <br />
                        </div>
                      ))}

                      <Alert
                        color="danger"
                        role="alert"
                        className="no-preview-in-landscape"
                      >
                        <i className="fa fas fa-mobile"></i>{" "}
                        <Trans i18nKey="badge.preview.portraitRenderOnly"></Trans>
                      </Alert>
                    </div>
                  )}
                </Col>
              </div>
            </Container>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
          {/*props.adminAccess ? (
            <div className="mb-3 footer bg-transparent">
              <div className="d-grid">
                <button
                  className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                  type="button"
                  disabled={props.loading}
                  onClick={() => {
                    dispatch(
                      accessAdmin({
                        idPlace: props.router.params.idPlace,
                        idBadge: props.router.params.idBadge,
                        token: props.router.params.token,
                        password: "",
                      })
                    )
                    props.close()
                  }}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <Trans i18nKey="badge.adminAccess.closeAdminAccess"></Trans>
                </button>
              </div>
            </div>
          ) : (
            ""
          ) */}
        </Modal>
      </div>
    </React.Fragment>
  )
}

AdminAccess.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  adminAccess: PropTypes.bool,
  status: PropTypes.string,
  show: PropTypes.bool,
  previousGames: PropTypes.array,
  close: PropTypes.func,
}

const mapStateToProps = state => {
  const { loading, status, adminAccess, previousGames } = state.Badge
  return {
    loading,
    status,
    adminAccess,
    previousGames,
  }
}
export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(AdminAccess))
)
