import {
  GET_ORDER,
  GET_ORDER_FAIL,
  GET_ORDER_SUCCESS,
  LIST_ORDERS,
  LIST_ORDERS_FAIL,
  LIST_ORDERS_SUCCESS,
  LAST_ORDERS,
  LAST_ORDERS_FAIL,
  LAST_ORDERS_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getOrder = (order, history) => {
  return {
    type: GET_ORDER,
    payload: { order, history },
  };
};

export const getOrderSuccess = order => {
   toast.success(i18n.t(order.message));
  return {
    type: GET_ORDER_SUCCESS,
    payload: order.details,
  };
};

export const getOrderFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_ORDER_FAIL,
    payload: fail,
  };
};

export const listOrders = history => {
  return {
    type: LIST_ORDERS,
    payload: { history },
  };
};

export const listOrdersSuccess = orders => {
  // toast.success(i18n.t(orders.message));
  return {
    type: LIST_ORDERS_SUCCESS,
    payload: orders.details,
  };
};

export const listOrdersFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LIST_ORDERS_FAIL,
    payload: fail,
  };
};

export const lastOrders = history => {
  return {
    type: LAST_ORDERS,
    payload: { history },
  };
};

export const lastOrdersSuccess = orders => {
  toast.success(i18n.t(orders.message));
  return {
    type: LAST_ORDERS_SUCCESS,
    payload: orders.details,
  };
};

export const lastOrdersFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LAST_ORDERS_FAIL,
    payload: fail,
  };
};
