import {
  GET_PLACE,
  GET_PLACE_FAIL,
  GET_PLACE_SUCCESS,
  LIST_PLACES,
  LIST_PLACES_FAIL,
  LIST_PLACES_SUCCESS,
  ADD_ORDER,
  ADD_ORDER_FAIL,
  ADD_ORDER_SUCCESS,
  CANCEL_ORDER,
  CANCEL_ORDER_FAIL,
  CANCEL_ORDER_SUCCESS,
  EDIT_PLACE,
  EDIT_PLACE_FAIL,
  EDIT_PLACE_SUCCESS
} from "./actionTypes"

const initialState = {
  error: null,
  place: null,
  places: null,
  loading: false,
  endorsementAdded: false,
  orderCancelled: false,
  editHardwareUpdated: false,
}

const Place = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLACE:
      state = {
        ...state,
        place: null,
        loading: true,
        error: null,
      }
      break
    case GET_PLACE_SUCCESS:
      state = {
        ...state,
        loading: false,
        place: { ...state.place, [action.payload.details.type]: action.payload.details.data },
        error: null,
      }
      break
    case GET_PLACE_FAIL:
      state = {
        ...state,
        loading: false,
        place: null,
        error: action.payload,
      }
      break
    case LIST_PLACES:
      state = {
        ...state,
        places: null,
        loading: true,
        error: null,
      }
      break
    case LIST_PLACES_SUCCESS:
      state = {
        ...state,
        loading: false,
        places: action.payload,
        error: null,
      }
      break
    case LIST_PLACES_FAIL:
      state = {
        ...state,
        loading: false,
        places: null,
        error: action.payload,
      }
      break
    case ADD_ORDER:
      state = {
        ...state,
        endorsementAdded: false,
        loading: true,
        error: null,
      }
      break
    case ADD_ORDER_SUCCESS:
      state = {
        ...state,
        endorsementAdded: true,
        loading: false,
        error: null,
      }
      break
    case ADD_ORDER_FAIL:
      state = {
        ...state,
        endorsementAdded: false,
        loading: false,
        error: action.payload,
      }
      break
    case CANCEL_ORDER:
      state = {
        ...state,
        orderCancelled: false,
        loading: true,
        error: null,
      }
      break
    case CANCEL_ORDER_SUCCESS:
      state = {
        ...state,
        orderCancelled: true,
        loading: false,
        error: null,
      }
      break
    case CANCEL_ORDER_FAIL:
      state = {
        ...state,
        orderCancelled: false,
        loading: false,
        error: action.payload,
      }
      break
    case EDIT_PLACE:
      state = {
        ...state,
        loading: true,
        error: null,
      }
      break
    case EDIT_PLACE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
      }
      break
    case EDIT_PLACE_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Place
