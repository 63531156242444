import React, { useState } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"

// import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Import menuDropdown
import LanguageDropdown from "../Common/LanguageDropdown"

//i18n
import { withTranslation } from "react-i18next"

import Rules from "../../pages/Public/Badge/Rules"
import BackButton from "./BackButton"
import AdminAccess from "../../pages/Public/Badge/AdminAccess"
// import images
// import logo from "assets/images/logo.png"

const Header = props => {
  // function toggleFullscreen() {
  //   if (
  //     !document.fullscreenElement &&
  //     /* alternative standard method */ !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement
  //   ) {
  //     // current working methods
  //     if (document.documentElement.requestFullscreen) {
  //       document.documentElement.requestFullscreen()
  //     } else if (document.documentElement.mozRequestFullScreen) {
  //       document.documentElement.mozRequestFullScreen()
  //     } else if (document.documentElement.webkitRequestFullscreen) {
  //       document.documentElement.webkitRequestFullscreen(
  //         Element.ALLOW_KEYBOARD_INPUT
  //       )
  //     }
  //   } else {
  //     if (document.cancelFullScreen) {
  //       document.cancelFullScreen()
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen()
  //     } else if (document.webkitCancelFullScreen) {
  //       document.webkitCancelFullScreen()
  //     }
  //   }
  // }

  const [showRules, setShowRules] = useState(false)
  const [showAdminAccess, setShowAdminAccess] = useState(false)

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {/*<div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logo} alt="" height="19" />
                </span>
              </Link>
            </div>*/}
            <BackButton />
          </div>

          <div className="d-flex">
            <div
              className={
                "dropdown d-inline " +
                (props.router.params.idPlace ? "" : "d-none")
              }
            >
              <button
                type="button"
                className="btn header-item noti-icon "
                style={{ paddingBottom: "0.15em" }}
                onClick={() => {
                  setShowRules(true)
                }}
              >
                <i className="bx bx-book-open" />
              </button>
            </div>
            <LanguageDropdown />
            <div
              className={
                "dropdown d-inline " +
                (props.router.params.idPlace ? "" : "d-none")
              }
            >
              <button
                type="button"
                className="btn header-item noti-icon "
                style={{ paddingBottom: "0.15em" }}
                onClick={() => {
                  setShowAdminAccess(true)
                }}
              >
                <i className="bx bx-lock-alt" />
              </button>
            </div>
            <AdminAccess
              show={showAdminAccess}
              close={() => setShowAdminAccess(false)}
            />
            <Rules show={showRules} close={() => setShowRules(false)} />

            {/*<div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                className="btn header-item noti-icon "
                onClick={() => {
                  toggleFullscreen()
                }}
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
              </div> */}
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  router: PropTypes.object,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Header))
)
