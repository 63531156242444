import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import withRouter from "components/Common/withRouter"
import { connect } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";

//store
import { getPlace } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

class Place extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (props.router.params.idPlace > 0) {
      const postData = {
        idPlace: props.router.params.idPlace,
        type: "info",
      };
      props.getPlace(postData, props.router.navigate);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (props.place && props.place.info
                    ? " : " + props.place.info.name
                    : "") +
                  " | GreenPlay Innovation"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {props.place && props.place.info ? (
              <div>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2 mt-3">
                          {props.router.params.type === "success" ? (
                            <div className="text-center">
                              <div className="avatar-md mx-auto">
                                <div className="avatar-title rounded-circle bg-light">
                                  <i className="bx bx-check h1 mb-0 text-success"></i>
                                </div>
                              </div>
                              <div className="p-2 mt-4">
                                <h4><Trans i18nKey="place.stripeSuccessTitle"></Trans></h4>
                                <p>
                                <Trans i18nKey="place.stripeThankYou"></Trans>{" "}
                                  <span className="fw-semibold">
                                    {new URLSearchParams(props.location.search).get("user")}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="text-center">
                              <div className="avatar-md mx-auto">
                                <div className="avatar-title rounded-circle bg-light">
                                  <i className="bx bx-x h1 mb-0 text-danger"></i>
                                </div>
                              </div>
                              <div className="p-2 mt-4">
                                <h4><Trans i18nKey="place.stripeFailedTitle"></Trans></h4>
                                <p>
                                <Trans i18nKey="place.stripeSorry"></Trans>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  router: PropTypes.object,
  loading: PropTypes.any,
  location: PropTypes.object,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, place } = state.Place;
  return { error, loading, place };
};

export default connect(mapStateToProps, {
  getPlace,
})(withRouter(withTranslation()(Place)));
