import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link } from "react-router-dom"
import withRouter from "../Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [authUser] = useState(JSON.parse(localStorage.getItem("authUser")))
  const [selectedIdPlace] = useState(parseInt(props.router.params.idPlace))
  let places = []
  if (authUser && authUser.places && authUser.places.length > 0) {
    places = authUser.places.find(function (obj) {
      return selectedIdPlace === obj.id
    })
  }
  const [selectedPlace, setSelectedPlace] = useState(places)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }

    const newSelectedIdPlace = parseInt(props.router.params.idPlace)
    if (authUser && authUser.places) {
      const newSelectedPlace = authUser.places.find(function (obj) {
        return newSelectedIdPlace === obj.id
      })
      setSelectedPlace(newSelectedPlace)
    }
  })

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div
        className="topnav"
        style={{
          backgroundColor: selectedPlace
            ? selectedPlace.dashboardColor
            : "#1a3a3f",
        }}
      >
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              {props.router.params.idPlace ? (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/place/" + props.router.params.idPlace}
                    >
                      <i className="bx bx-home-circle me-2" />
                      {props.t("navbar.home")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/place/" + props.router.params.idPlace + "/orders"}
                    >
                      <i className="bx bx-copy-alt me-2" />
                      {props.t("navbar.orders")}
                    </Link>
                  </li>

                  {
                    // <li className="nav-item">
                    //   <Link
                    //     className="nav-link"
                    //     to={
                    //       "/place/" + props.router.params.idPlace + "/settings"
                    //     }
                    //   >
                    //     <i className="bx bx-cog me-2" />
                    //     {props.t("navbar.settings")}
                    //   </Link>
                    // </li>
                  }

                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/place/" + props.router.params.idPlace + "/hardware"}
                    >
                      <i className="bx bx-devices me-2" />
                      {props.t("navbar.hardware")}
                    </Link>
                  </li>

                  {/*<li className="nav-item">
                  <Link
                    className="nav-link"
                    to={
                      "/place/" +
                      props.router.params.idPlace +
                      "/invoicing"
                    }
                  >
                    <i className="bx bx-credit-card me-2" />
                    {props.t("navbar.invoicing")}
                  </Link>
                  </li>*/}
                </ul>
              ) : (
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to={"/account"}>
                      <i className="bx bx-user me-2" />
                      {props.t("profile.title")}
                    </Link>
                  </li>
                </ul>
              )}
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  menuOpen: PropTypes.any,
  router: PropTypes.object,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
