import { takeEvery, fork, put, call, take } from "redux-saga/effects"
import { eventChannel, END } from "redux-saga"

// Login Redux States
import {
  GET_BADGE,
  SET_STATUS,
  RESET,
  PRINT_SCORES,
  ACCESS_ADMIN,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_PROGRESS,
  SEND_EMAIL,
  SEND_SURVEY,
  TAKE_CONTROL,
} from "./actionTypes"
import {
  getBadgeSuccess,
  getBadgeFail,
  setStatusSuccess,
  setStatusFail,
  resetSuccess,
  resetFail,
  printScoresSuccess,
  printScoresFail,
  accessAdminSuccess,
  accessAdminFail,
  uploadPhotoSuccess,
  uploadPhotoFail,
  uploadPhotoProgress,
  sendEmailSuccess,
  sendEmailFail,
  sendSurveySuccess,
  sendSurveyFail,
  takeControlSuccess,
  takeControlFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBadgeCall,
  setStatusCall,
  resetCall,
  printScoresCall,
  accessAdminCall,
  uploadPhotoCall,
  sendEmailCall,
  sendSurveyCall,
  takeControlCall,
} from "../../helpers/backend"

function* getBadge({ payload: { badge, history } }) {
  try {
    const response = yield call(getBadgeCall, badge)
    if (response.status === "success") {
      // localStorage.setItem("badge", JSON.stringify(response.details))
      // if (response.details.status === "disabled") {
      //   localStorage.removeItem("game")
      //   localStorage.removeItem("scores")
      // }
      yield put(getBadgeSuccess(response.details))
      if (badge.token === "")
        history(
          "/" +
            badge.idBadge +
            "/" +
            response.details.client +
            "/" +
            response.details.token
        )
    } else yield put(getBadgeFail(response))
  } catch (error) {
    yield put(getBadgeFail(error))
  }
}

function* setStatus({ payload: { badge, history } }) {
  try {
    const response = yield call(setStatusCall, badge)
    if (response.status === "success") {
      // localStorage.setItem("badge", JSON.stringify(response.details))
      // if (response.details.status === "enabled") {
      //   localStorage.removeItem("game")
      //   localStorage.removeItem("scores")
      // }
      yield put(setStatusSuccess(response.details))
    } else {
      yield put(setStatusFail(response))
      history("/" + badge.idBadge)
    }
  } catch (error) {
    yield put(setStatusFail(error))
  }
}

function* reset({ payload: { badge, history } }) {
  try {
    const response = yield call(resetCall, badge)
    if (response.status === "success") {
      // localStorage.removeItem("badge")
      // localStorage.removeItem("game")
      // localStorage.removeItem("scores")
      yield put(resetSuccess(response.details))
      history(
        "/" + badge.idBadge + "/" + badge.idPlace + "/" + response.details.token
      )
    } else yield put(resetFail(response))
  } catch (error) {
    yield put(resetFail(error))
  }
}

function* printScores({ payload: { badge } }) {
  try {
    const response = yield call(printScoresCall, badge)
    if (response.status === "success") {
      yield put(printScoresSuccess(response))
    } else yield put(printScoresFail(response))
  } catch (error) {
    yield put(printScoresFail(error))
  }
}

function* accessAdmin({ payload: { badge } }) {
  try {
    const response = yield call(accessAdminCall, badge)
    if (response.status === "success") {
      yield put(accessAdminSuccess(response.details))
    } else yield put(accessAdminFail(response))
  } catch (error) {
    yield put(accessAdminFail(error))
  }
}

function createUploader(upload) {
  let emit
  const chan = eventChannel(emitter => {
    emit = emitter
    return () => {} // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  })

  const uploadPromise = uploadPhotoCall(upload, event => {
    emit(event.progress)
    if (event.loaded >= event.total) {
      emit(END)
    }
  })

  return [uploadPromise, chan]
}

function* watchOnProgress(chan) {
  while (true) {
    const response = yield take(chan)
    // if (response.progress !== null)
    yield put(uploadPhotoProgress(response * 100))
    // yield put({ type: "PROGRESS", payload: data });
  }
}

function* uploadPhoto({ payload: { badge } }) {
  // try {
  //   const response = yield call(uploadPhotoCall, badge)
  //   if (response.status === "success") {
  //     localStorage.setItem("badge", JSON.stringify(response.details))
  //     yield put(uploadPhotoSuccess(response.details))
  //   } else yield put(uploadPhotoFail(response))
  // } catch (error) {
  //   yield put(uploadPhotoFail(error))
  // }

  const [uploadPromise, chan] = createUploader(badge)
  yield fork(watchOnProgress, chan)

  try {
    const response = yield call(() => uploadPromise)
    if (response.status === "success") {
      yield put(uploadPhotoSuccess(response))
      // history("/upload/" + badge.idComputer);
    } else yield put(uploadPhotoFail(response))
  } catch (error) {
    yield put(uploadPhotoFail(error))
  }
}

function* sendEmail({ payload: { badge } }) {
  try {
    const response = yield call(sendEmailCall, badge)
    if (response.status === "success") {
      // localStorage.setItem("badge", JSON.stringify(response.details))
      yield put(sendEmailSuccess(response.details))
    } else yield put(sendEmailFail(response))
  } catch (error) {
    yield put(sendEmailFail(error))
  }
}

function* sendSurvey({ payload: { badge } }) {
  try {
    const response = yield call(sendSurveyCall, badge)
    if (response.status === "success") {
      // localStorage.setItem("badge", JSON.stringify(response.details))
      yield put(sendSurveySuccess(response.details))
    } else yield put(sendSurveyFail(response))
  } catch (error) {
    yield put(sendSurveyFail(error))
  }
}

function* takeControl({ payload: { badge } }) {
  try {
    const response = yield call(takeControlCall, badge)
    if (response.status === "success") {
      // localStorage.setItem("badge", JSON.stringify(response.details))
      yield put(takeControlSuccess(response.details))
    } else yield put(takeControlFail(response))
  } catch (error) {
    yield put(takeControlFail(error))
  }
}

function* badgeSaga() {
  yield takeEvery(GET_BADGE, getBadge)
  yield takeEvery(SET_STATUS, setStatus)
  yield takeEvery(RESET, reset)
  yield takeEvery(PRINT_SCORES, printScores)
  yield takeEvery(ACCESS_ADMIN, accessAdmin)
  yield takeEvery(UPLOAD_PHOTO, uploadPhoto)
  yield takeEvery(UPLOAD_PHOTO_PROGRESS, uploadPhotoProgress)
  yield takeEvery(SEND_EMAIL, sendEmail)
  yield takeEvery(SEND_SURVEY, sendSurvey)
  yield takeEvery(TAKE_CONTROL, takeControl)
}

export default badgeSaga
