import {
  GET_HARDWARE,
  GET_HARDWARE_FAIL,
  GET_HARDWARE_SUCCESS,
  LIST_HARDWARE,
  LIST_HARDWARE_FAIL,
  LIST_HARDWARE_SUCCESS,
  EDIT_HARDWARE,
  EDIT_HARDWARE_FAIL,
  EDIT_HARDWARE_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getHardware = (hardware, history) => {
  return {
    type: GET_HARDWARE,
    payload: { hardware, history },
  };
};

export const getHardwareSuccess = hardware => {
  toast.success(i18n.t(hardware.message));
  return {
    type: GET_HARDWARE_SUCCESS,
    payload: hardware.details,
  };
};

export const getHardwareFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_HARDWARE_FAIL,
    payload: fail,
  };
};

export const listHardware = history => {
  return {
    type: LIST_HARDWARE,
    payload: { history },
  };
};

export const listHardwareSuccess = hardware => {
  // toast.success(i18n.t(hardware.message));
  return {
    type: LIST_HARDWARE_SUCCESS,
    payload: hardware.details,
  };
};

export const listHardwareFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LIST_HARDWARE_FAIL,
    payload: fail,
  };
};

export const editHardware = (hardware, history) => {
  return {
    type: EDIT_HARDWARE,
    payload: { hardware, history },
  };
};

export const editHardwareSuccess = hardware => {
  toast.success(i18n.t(hardware.message));
  return {
    type: EDIT_HARDWARE_SUCCESS,
    payload: hardware.details,
  };
};

export const editHardwareFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: EDIT_HARDWARE_FAIL,
    payload: fail,
  };
};