import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap"
import { connect, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

// import { Helmet, HelmetProvider } from "react-helmet-async";

// notification
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

//store
import { getPlace } from "../../store/actions"

//i18n
// import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next"

const Place = props => {
  const dispatch = useDispatch()
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (props.router.params.idPlace > 0) {
      dispatch(
        getPlace(
          {
            idPlace: props.router.params.idPlace,
            type: "info",
          },
          props.router.navigate
        )
      )
    }
  }, [props.router.params.idPlace])

  return (
    <React.Fragment>
      <div className="page-content">
        {/*<HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (props.place && props.place.info
                    ? " : " + props.place.info.name
                    : "") +
                  " | GreenPlay Innovation"}
              </title>
            </Helmet>
          </HelmetProvider>
                  */}
        <Container fluid>
          {props.place && props.place.info ? (
            <div>
              <Row className="justify-content-center">
                <Col lg="9">
                  <Row>
                    <Col md="6">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                <Trans i18nKey="place.orders"></Trans>
                              </p>
                              <h4 className="mb-0">
                                {props.place.info.orders}
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                              style={{
                                backgroundColor:
                                  props.place.info.dashboardColor,
                              }}
                            >
                              <span className="avatar-title">
                                <i className={"bx bx-copy-alt font-size-24"} />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                <Trans i18nKey="place.revenue"></Trans>
                              </p>
                              <h4 className="mb-0">
                                {props.place.info.revenue} €
                              </h4>
                            </div>
                            <div
                              className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                              style={{
                                backgroundColor:
                                  props.place.info.dashboardColor,
                              }}
                            >
                              <span className="avatar-title">
                                <i
                                  className={"bx bx-archive-in font-size-24"}
                                />
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Card>
                        <CardBody>
                          <h4 className="mb-4 card-title">
                            <Trans i18nKey="place.thisMonth"></Trans>
                          </h4>
                          <Row>
                            <Col md="6">
                              <p className="text-muted">
                                <Trans i18nKey="place.monthlyRevenue"></Trans>
                              </p>
                              <h3>{props.place.info.montlyRevenue} €</h3>
                              <p className="text-muted">
                                {props.place.info.previousMonthlyRevenue > 0 ? (
                                  props.place.info.montlyRevenue >
                                  props.place.info.previousMonthlyRevenue ? (
                                    <span className="text-success me-2">
                                      {" " +
                                        parseInt(
                                          ((props.place.info.montlyRevenue -
                                            props.place.info
                                              .previousMonthlyRevenue) *
                                            100) /
                                            props.place.info
                                              .previousMonthlyRevenue
                                        )}
                                      % <i className="mdi mdi-arrow-up"></i>
                                    </span>
                                  ) : (
                                    <span className="text-danger me-2">
                                      {" " +
                                        parseInt(
                                          ((props.place.info.montlyRevenue -
                                            props.place.info
                                              .previousMonthlyRevenue) *
                                            100) /
                                            props.place.info
                                              .previousMonthlyRevenue
                                        )}
                                      % <i className="mdi mdi-arrow-down"></i>
                                    </span>
                                  )
                                ) : (
                                  <span className="text-muted me-2">
                                    {" 0"}%{" "}
                                    <i className="mdi mdi-window-minimize"></i>
                                  </span>
                                )}{" "}
                                <Trans i18nKey="place.FromPreviousPeriod"></Trans>
                              </p>
                            </Col>
                            <Col md="6">
                              <p className="text-muted">
                                <Trans i18nKey="place.monthlyInvoice"></Trans>
                              </p>
                              <h3>{props.place.info.montlyInvoice} €</h3>
                              {/*<div className="mt-3">
                              <Link
                                to={"/place/" + props.place.info.idPlace + "/invoicing"}
                                className="btn btn-primary waves-effect waves-light btn-sm"
                                style={{
                                  borderColor: props.place.info.dashboardColor,
                                  backgroundColor: props.place.info.dashboardColor,
                                }}
                              >
                              <Trans i18nKey="place.needMoreInfoAboutInvoicing"></Trans>{" "}
                                <i className="mdi mdi-arrow-right ms-1"></i>
                              </Link>
                              </div>*/}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Card>
                    <CardBody>
                      <div>
                        <h5 className="mb-3">
                          <Trans i18nKey="place.stock" />
                        </h5>
                        <Alert
                          color={
                            props.place.info.paperStockLeft <= 0
                              ? "danger"
                              : props.place.info.paperStockLeft <= 10
                              ? "warning"
                              : "success"
                          }
                        >
                          <i className="far fa-copy"></i>{" "}
                          <Trans i18nKey="place.paper" /> :{" "}
                          {props.place.info.paperStockLeft}
                        </Alert>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Place.propTypes = {
  t: PropTypes.any,
  router: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
}

const mapStateToProps = state => {
  const { error, loading, place } = state.Place
  return { error, loading, place }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Place))
)
