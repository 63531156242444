import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap"

import "react-phone-number-input/style.css"
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input"

import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

import { Helmet, HelmetProvider } from "react-helmet-async"

// Redux
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import noavatar from "../../assets/images/user.png"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import i18n from "../../i18n/i18n"
import { Trans, withTranslation } from "react-i18next"

// actions
import { getProfile, editProfile } from "../../store/actions"

import timezones from "../../assets/json/timezones.json"

const Profile = props => {
 

  useEffect(() => {
    props.getProfile(props.router.navigate)
  }, [])

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{i18n.t("profile.title") + " | GreenPlay Innovation"}</title>
        </Helmet>
      </HelmetProvider>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-md-center">
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mb-3 text-center">
                    <img
                      referrerPolicy="no-referrer"
                      alt="avatar"
                      src={props.user.avatar ? props.user.avatar : noavatar}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = noavatar
                      }}
                      className="avatar-md rounded-circle img-thumbnail"
                    />
                  </div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      email: (props.user && props.user.email) || "",
                      firstname: (props.user && props.user.firstname) || "",
                      lastname: (props.user && props.user.lastname) || "",
                      phone: (props.user && props.user.phone) || "",
                      timezone:
                        (props.user && props.user.timezone) || "Europe/Paris",
                      country:
                        (props.user && props.user.country) ||
                        i18n.t("profile.defaultCountry") ||
                        "",
                    }}
                    validationSchema={Yup.object().shape({
                      firstname: Yup.string().required(
                        i18n.t("profile.enterFirstname")
                      ),
                      lastname: Yup.string().required(
                        i18n.t("profile.enterLastname")
                      ),
                      email: Yup.string()
                        .email(i18n.t("profile.enterValidEmail"))
                        .required(i18n.t("profile.enterEmail")),
                      phone: Yup.string()
                        .required(i18n.t("profile.enterPhone"))
                        .test({
                          name: "phone",
                          message: i18n.t("profile.enterValidPhone"),
                          test: function (value) {
                            if (value === undefined) return false
                            return isValidPhoneNumber(value)
                          },
                        }),
                      timezone: Yup.string().required(
                        i18n.t("profile.enterTimezone")
                      ),
                    })}
                    onSubmit={values => {
                      props.editProfile(values, props.router.navigate)
                    }}
                  >
                    {({ handleBlur, setFieldValue, errors, touched }) => (
                      <Form className="form-horizontal">
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="firstname" className="form-label">
                                <Trans i18nKey="profile.firstname" />*
                              </Label>
                              <Field
                                name="firstname"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.firstname && touched.firstname
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="firstname"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="lastname" className="form-label">
                                <Trans i18nKey="profile.lastname" />*
                              </Label>
                              <Field
                                name="lastname"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.lastname && touched.lastname
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Col>
                        </Row>

                        <div className="mb-3">
                          <Label for="email" className="form-label">
                            <Trans i18nKey="profile.email" />*
                          </Label>
                          <Field
                            name="email"
                            type="email"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                            onChange={e => {
                              const value = e.target.value || ""
                              setFieldValue("email", value.toLowerCase())
                            }}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="phone" className="form-label">
                            <Trans i18nKey="profile.phone" />*
                          </Label>

                          <PhoneInput
                            name="phone"
                            type="tel"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            defaultCountry={i18n.t("profile.defaultCountry")}
                            value={props.user.phone}
                            onChange={phone => {
                              setFieldValue("phone", phone)
                            }}
                            onCountryChange={countryCode => {
                              setFieldValue("country", countryCode)
                            }}
                            countries={["FR", "BE"]}
                          />

                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="timezone" className="form-label">
                            <Trans i18nKey="profile.timezone" />*
                          </Label>
                          <Field
                            name="timezone"
                            as="select"
                            className={
                              "form-control" +
                              (errors.timezone && touched.timezone
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <option value="" disabled={true}>
                              {i18n.t("profile.chooseTimezone")}
                            </option>
                            {timezones.map((hour, key) => (
                              <option value={hour.value} key={"hour-" + key}>
                                {hour.text}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="timezone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="text-center mt-4">
                          <Button
                            type="submit"
                            color="primary"
                            disabled={props.loading}
                          >
                            <Trans i18nKey="profile.update"></Trans>
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.Fragment>
  )
}

Profile.propTypes = {
  router: PropTypes.object,
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  getProfile: PropTypes.func,
  user: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStateToProps = state => {
  const { error, success, user, loading } = state.Profile
  return { error, success, user, loading }
}

export default withRouter(
  connect(mapStateToProps, { getProfile, editProfile })(
    withTranslation()(Profile)
  )
)
