export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const LIST_USERS = "LIST_USERS";
export const LIST_USERS_SUCCESS = "LIST_USERS_SUCCESS";
export const LIST_USERS_FAIL = "LIST_USERS_FAIL";

export const LOGIN_AS = "LOGIN_AS";
export const LOGIN_AS_SUCCESS = "LOGIN_AS_SUCCESS";
export const LOGIN_AS_FAIL = "LOGIN_AS_FAIL";