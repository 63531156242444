import PropTypes from "prop-types"
import React from "react"

import { Container, Row, Col, Alert } from "reactstrap"

//redux
//redux
import { connect, useDispatch } from "react-redux" // , useDispatch
import withRouter from "components/Common/withRouter"

// i18n
import { Trans, withTranslation } from "react-i18next"

// effect
import { Fade } from "react-awesome-reveal"

// bucket
import { ROOT_PROD_BUCKET } from "../../../helpers/url"

// actions
import { setStatus } from "../../../store/actions"

const Preview = props => {
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <Fade>
        <Container>
          <div className="text-center pt-5 mt-5 mb-5">
            <Row className="m-3">
              <Col className="offset-1 col-10">
                <h3 className="text-primary fw-semibold mb-3">
                  <Trans i18nKey="badge.preview.title"></Trans>
                </h3>
                <img
                  src={
                    ROOT_PROD_BUCKET +
                    "/config/" +
                    props.router.params.idPlace +
                    "/badges/" +
                    props.router.params.idBadge +
                    ".jpg?timestamp=" +
                    Date.now()
                  }
                  alt=""
                  className="img-fluid img-thumbnail img-preview"
                />
                <Alert
                  color="danger"
                  role="alert"
                  className="no-preview-in-landscape"
                >
                  <i className="fa fas fa-mobile"></i>{" "}
                  <Trans i18nKey="badge.preview.portraitRenderOnly"></Trans>
                </Alert>
              </Col>
            </Row>
          </div>
          <div className="mb-3 footer bg-transparent">
            <div className="d-grid">
              <button
                className="btn btn-rounded btn-primary btn-block btn-lg fw-semibold"
                type="button"
                disabled={props.loading}
                onClick={() => {
                  dispatch(
                    setStatus({
                      idPlace: props.router.params.idPlace || "",
                      idBadge: props.router.params.idBadge || 0,
                      token: props.router.params.token || "",
                      status: "sendEmail",
                    },
                    props.router.navigate)
                  )
                }}
              >
                <Trans i18nKey="badge.preview.submit"></Trans>
              </button>
            </div>
          </div>
        </Container>
      </Fade>
    </React.Fragment>
  )
}

Preview.propTypes = {
  router: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string,
}

const mapStateToProps = state => {
  const { loading, status } = state.Badge
  return { loading, status }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(Preview))
)
