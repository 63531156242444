import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { GET_HARDWARE, LIST_HARDWARE, EDIT_HARDWARE } from "./actionTypes";
import {
  getHardwareSuccess,
  getHardwareFail,
  listHardwareSuccess,
  listHardwareFail,
  editHardwareSuccess,
  editHardwareFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getHardwareCall,
  listHardwareCall,
  editHardwareCall,
} from "../../helpers/backend";

function* getHardware({ payload: { hardware, history } }) {
  try {
    const response = yield call(hardware, getHardwareCall);
    if (response.status === "success") {
      yield put(getHardwareSuccess(response));
    } else {
      yield put(getHardwareFail(response));
    }
  } catch (error) {
    yield put(getHardwareFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* listHardware({ payload: { history } }) {
  try {
    const response = yield call(listHardwareCall);
    if (response.status === "success") {
      yield put(listHardwareSuccess(response));
    } else {
      yield put(listHardwareFail(response));
    }
  } catch (error) {
    yield put(listHardwareFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

function* editHardware({ payload: { place, history } }) {
  try {
    const response = yield call(editHardwareCall, place);
    if (response.status === "success") {
      yield put(editHardwareSuccess(response));
    } else {
      yield put(editHardwareFail(response));
    }
  } catch (error) {
    yield put(editHardwareFail(error));
    if (error === "backend.invalidCredentials") history("/logout");
  }
}

export function* watchHardware() {
  yield takeEvery(GET_HARDWARE, getHardware);
  yield takeEvery(LIST_HARDWARE, listHardware);
  yield takeEvery(EDIT_HARDWARE, editHardware);
}

function* HardwareSaga() {
  yield all([fork(watchHardware)]);
}

export default HardwareSaga;
